import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Button, Form, Segment, Grid, Header, Icon } from 'semantic-ui-react';


class SongNew extends Component {

render() {
  return(
    <Segment placeholder color='purple'>
      <Grid columns={2} stackable textAlign='center'>
        <Grid.Row>
          <Header icon>
            <Icon name='music' style={{ marginTop: '0.4em' }}  />
            Add New Song
          </Header>
        </Grid.Row>
        <Form>
          <Form.Input focus placeholder='Song Name' />    

{/********** Code to upload new song file follows **********/}

          <Button 
            basic 
            color='violet'
            content="Choose Song File"
            labelPosition="left"
            icon="music"
            // onClick={() => this.fileInputRef.current.click()}
          />
          <input
            type="file"
            hidden
            // ref={this.fileInputRef}
            // onChange={this.fileChange}
          />

{/* Use this link as reference https://codesandbox.io/s/04lz4580pl */}

{/********** End code to upload new song **********/}

            <br />
          <Button.Group>
            <Button color='purple' as={Link} to='/songs'>Create</Button>
          <Button.Or />
            <Button color='purple' as={Link} to='/songs'>Cancel</Button>
          </Button.Group>   
        </Form>
      </Grid>
       <br />
    </Segment>
  ) 
}}

export default SongNew;