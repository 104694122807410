import React, { Component } from 'react';
import {Link, Redirect } from 'react-router-dom';
import { Image, Button, Form, Segment, Grid, Header, Icon, Progress } from 'semantic-ui-react';
import Resizer from 'react-image-file-resizer'
import {database, storage} from '../backend/config';
import firebase from '../backend/config'

class AlbumNew extends Component {
  
  constructor(props) {
    super(props)
    console.log(props.props.props)
    console.log(props.props.props.albums.bndId)
    this.state = {
      bandId: props.props.props.albums.bndId,
      albumArt: {albumArtFile: null,
                  progress: 0 },
      album: props.props.props.albums,
      fileTemp: null
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.startAddingAlbum = this.startAddingAlbum.bind(this);
    this.startGettingAlbumInfo = this.startGettingAlbumInfo.bind(this)
  }

  componentDidMount() {
    console.log(this.state)
    if (this.state.album !== undefined) {
      this.setState({bandId: this.state.album.bndId})
      this.startGettingAlbumInfo(this.state.album.id)
    }
  }


  startGettingAlbumInfo(albumId) {
    if(albumId === undefined)
    return
  console.log("starting to get album information " + albumId)
  database.ref('albums').orderByChild('id').equalTo(albumId).once("value", snapshot =>{
    console.log(snapshot)
    // let genres=[]
    snapshot.forEach(data => {
      console.log(data.toJSON())

      this.setState({album: {
                      id: albumId,
                      albnm: data.toJSON().albnm,
                      key: data.toJSON().key,
                    } ,
                    bandId: data.toJSON().key
      })
    })
  }).catch(error => {
    console.log(error)
  })   
  }


  fileInputRef = React.createRef()

  handleChange = e => {
    console.log("handleChange")
    if(e.target.files[0]) {
        const albumArt = {albumArtFile: e.target.files[0]};
        this.setState(() => ({albumArt: albumArt}));
        console.log(albumArt)
        this.setState({
          fileTemp: URL.createObjectURL(e.target.files[0])
        })
    }
   
}

  handleSubmit(event) {
    event.preventDefault();
    console.log("handleSubmit")
    const albumName = event.target.elements.albumName.value
    console.log(event.target.elements.albumName.value)
    console.log(this.state)
    const albumProps={
        bndId: this.state.bandId,
        albnm: albumName,
        key: this.state.album.albId
    }
    console.log(albumProps)
    console.log(this.state)
    if(albumName !=="") {
        this.startAddingAlbum(albumProps) 
       // this.handleUpload(albumProps.id);
   }  
}


handleUpload = (albumId) => {
  console.log("handleUpload")
  console.log(albumId)
  console.log(this.state.albumArt)
  if(this.state.albumArt.albumArtFile!==null) {
    const {albumArtFile}=this.state.albumArt
    Resizer.imageFileResizer(
        albumArtFile,
        300,
        300,
        'JPEG',
        50,
        0,
        uri => {
          const uploadTask = storage.ref(`albumArt/${albumId}.jpg`).put(uri);
          uploadTask.on('state_changed', 
          (snapshot) => {
              const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes)*100);
              this.setState({progress})
              console.log(progress)
              if (progress===100) {
              // alert("Upload Complete")
              }
          }, 
              (error) => {
                  console.log(error);
              }
          );
        },
        'blob'
    )
  }
  else {
    this.setState({progress: 100})
  }
  console.log(albumId)
}


startAddingAlbum(albumProps) {
  albumProps.rnd = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)
  console.log("startAddingAlbum")
  console.log(albumProps)
      // let albumPath = "albums/" + albumProps.id
      // let updateObject ={
      //     [albumPath]: albumProps
      // }
      // database.ref().update(updateObject).then(() => {
      // }).catch((error) => {
      //     console.log(error)
      // })
    //  console.log(updateObject)



    

      var albumPropsKey = albumProps.key
      //if key exist, update existing entry
      if(albumProps.key!==undefined) {
        let albumPath = "albums/" + albumProps.key
        delete albumProps.key
        let updateObject ={
            [albumPath]: albumProps
        }
        database.ref().update(updateObject).then(() => {
          this.handleUpload(albumPropsKey)
          }).catch((error) => {
              console.log(error)
          })
      } 
      //if does not exist, create new entry
      else {  
        delete albumProps.key
        console.log(albumProps)
        const albumRef = firebase.database().ref('albums');
        albumRef.push(albumProps).then((snap) => {
          this.handleUpload(snap.key)
        })
      }
  
}

  
render() {
  console.log("progress: " + this.state.progress)
  if(this.state.progress!==100)
  {
    console.log(this.state)
    var albumArtUrl
    var albumName
    var bandId
    var albumId
    if(this.state.album === undefined) {
      albumArtUrl = 'SwaraCoverArt.png'
      albumName = ""
      bandId = ""
      albumId = ""
    }
    else {
      albumArtUrl = this.state.album.albumArtUrl
      albumName = this.state.album.albnm
      bandId = this.state.album.bndId
      albumId = this.state.album.albId
    }
    return(
      <Segment placeholder color='purple'>
        <Grid stackable textAlign='center'>
          <Grid.Row>
            <Header icon>
              <Icon name='microphone' style={{ marginTop: '0.4em' }} />
                Add New Album
            </Header>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>

  {/********** Code to upload new Album file follows **********/}

            <Image 
              //src={require('../../images/SwaraCoverArt.png')}
              src={(this.state.fileTemp ===null ? albumArtUrl :this.state.fileTemp )}
            />
            </Grid.Column>
            <Grid.Column verticalAlign='middle'>
              <Form onSubmit={this.handleSubmit} fluid>
                <Form.Input name="albumName" defaultValue={this.state.album !== undefined? this.state.album.albnm: ""} focus placeholder='Album Name' />    
                  <Button 
                    fluid
                    basic 
                    color='violet'
                    content="Choose Album Art"
                    labelPosition="left"
                    icon="file"
                    type="button"
                    onClick={() => this.fileInputRef.current.click()}
                  />
                <input
                  type="file" 
                  hidden 
                  accept=".jpg" 
                  onChange={this.handleChange}
                  ref={this.fileInputRef}
                  // ref={this.fileInputRef}
                  // onChange={this.fileChange}
                />

  {/* Use this link as reference https://codesandbox.io/s/04lz4580pl */}

  {/********** End code to upload new Album **********/}

            {this.state.progress !== undefined ? <Progress size='tiny' color='violet' percent={this.state.progress}/> : <div><br/><br/></div>}
            <Button.Group>
              <Button color='purple' type="submit">{this.state.album.albId !== undefined ? "Update" : "Create"} </Button>
            <Button.Or />

            {this.state.album.albId !== undefined ? 
            <Link to = {{
                    pathname: '/songs',
                    state: {
                      params: {albnm: albumName,
                              bndId: bandId,
                              key: albumId,
                              albumArtUrl: albumArtUrl}
                    }
                }}>
              <Button color='purple'>Cancel</Button>
            </Link>
            :
            <Link to = {{
              pathname: '/bands'
                }}>
              <Button color='purple'>Cancel</Button>
            </Link>
              }
            </Button.Group>   
          </Form>
          </Grid.Column>
          </Grid.Row>
        </Grid>
          <br />
      </Segment>
    )
  }
  else {
    return (
      <Redirect to = "/bands"/>
    )
  }
}}

export default AlbumNew;