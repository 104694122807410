import React, { Component } from 'react';
import firebase from "../backend/config";
import {
  Grid,
  Form,
  Segment,
  Button,
  Header,
  Icon,
  Divider,
} from "semantic-ui-react";
import { Link } from 'react-router-dom';



class SignUp extends Component {

  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      error: null,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }
 
 
  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };


 handleSubmit = (event) => {
    console.log("handle submit")
    event.preventDefault();
    const { email, password } = this.state;
    console.log(email)
    console.log(password)
      firebase.auth().createUserWithEmailAndPassword(email, password)
      .catch(function(error) {
        // Handle Errors here.
        console.log(error.code)
        console.log(error.message)
        window.alert(error.message)
    })
  };




  render() {
    return (
      <div>
        <Grid textAlign="center" verticalAlign="middle">
          <Grid.Column style={{ maxWidth: 450 }}>
            <Form onSubmit={this.handleSubmit} size="large">
              <Segment raised>
              <Header as='h3'>Sign Up With Email To Rock!</Header>
                <Form.Input
                  fluid
                  name="email"
                  icon="mail"
                  iconPosition="left"
                  placeholder="Email Address"
                  type="email"
                  onChange={this.handleInputChange}
                />

                <Form.Input
                  fluid
                  name="password"
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password"
                  type="password"
                  onChange={this.handleInputChange}
                />
      
                <Button type="submit"
            
                  color="violet"
                  fluid
                  size="large"
                >
                Sign Up
                </Button>
              </Segment>
            </Form>

            <Divider horizontal inverted>Or</Divider>

            <Form size="large">
              <Segment raised>
                <Button as={ Link } to='/login' color="google plus" fluid size="large" icon labelPosition='left'>
                  <Icon name='google' />
                    Sign In With Google
                </Button>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>

      </div>
    );
  };
}
  
export default SignUp;
