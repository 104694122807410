import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Container,
  Grid,
  Icon,
  Image,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
  Header,
} from 'semantic-ui-react';

import {auth} from '../backend/config';


const getWidth = () => {
  const isSSR = typeof window === 'undefined'

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}

const HomepageHeading = (props, { mobile }) => (
  <Container text textAlign='center'>
    <Segment floated>
      <Grid stackable textAlign='center'>
        <Grid.Row>
          <Header icon>
            <Icon name='user circle' style={{ marginTop: '0.5em' }}/> Welcome
              {/* Welcome {this.props.userAuth.displayName || this.props.userAuth.email}! */}
          </Header>
            <Segment basic>
              <p>
               You are about to join the Swara revolution!
               We believe that there is gifted musical talent in every city which deserves an audience.  
               By adding your band, you help us connect your music to millions of new listeners looking for <b>live. local. music.</b>
              </p>
              <p>
               Please review the Copyright Policy below.
              </p>
              <h3>Copyright Policy</h3>
              <p>
               Music uploaded to this site must be of original works by the user.  By uploading the music, 
               the user is allowing Swara to stream the music to users and declaring that the music is an original work.  
               Music reported to not be of original work of the user will be promptly removed from the site.  
              </p>
              <p>
               By clicking <b>Add Album</b> below, you acknowledge that you have read the copyright policy and that
               the music you are adding is yours, and needs to be heard. If not, please click return to go back to your
               band page.
              </p>
            </Segment>
        </Grid.Row>
        <Grid.Row>
          <Button.Group>
            <Link to = {{
              pathname: '/new_album',
              state: {
                params: props.props
              }
            }} {...console.log(props.props)}>
              <Button color='purple' style={{ marginBottom: '0.5em' }}>Add Album</Button>
            </Link>
          <Button.Or />

          <Link to = {{
              pathname: '/bands',
              state: {
                selectedBand: props.props.albums.bndId
              }
            }} {...console.log(props.props)}>
              <Button color='purple' style={{ marginBottom: '0.5em' }}>Return</Button>
            </Link>



            
          </Button.Group>
        </Grid.Row><br />
      </Grid>
    </Segment>
  </Container>
)


HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}

class DesktopContainer extends Component {

  constructor(props) {
    super(props)
    console.log(props.props)

  }

  state = {}

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

logout() {
  auth.signOut()
  .then(() => {
  console.log("logged out")
  })
}
  
  render() {
    const { children } = this.props
    const { fixed } = this.state

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            textAlign='center'
            style={styles.hero}
            vertical
          >
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              secondary={!fixed}
              size='large'
            >
              <Container>
                <Menu.Item as={ Link } to='/'>
                  <Image
                    src={require('../../images/newLogo.png')}
                    size='medium'
                  />
                </Menu.Item>
                <Menu.Item>Beta V2</Menu.Item>
                <Menu.Item position='right'>
                  <Button as={ Link } to='/' inverted={!fixed} color='purple' onClick={this.logout}>
                    Log Out
                  </Button> 
                </Menu.Item>
              </Container>
            </Menu>
            <HomepageHeading props={this.props.props}/>
          </Segment>
        </Visibility>
        {children}
      </Responsive>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  constructor(props) {
    super(props)
    console.log(props.props)

  }

  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })
  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          as={Menu}
          animation='push'
          inverted
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
        >
          <Menu.Item as={ Link } to="/bandcopyright">Copyright</Menu.Item>
          <Menu.Item>Agree to Copyright Policy.</Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            textAlign='center'
            style={styles.heroMobile}
            vertical
          >
            <Container>
              <Menu inverted pointing secondary size='large'>
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name='sidebar' />
                </Menu.Item>
                <Menu.Item as={ Link } to='/' position='right'>
                  <Image
                    style={{ marginLeft: '0.5em' }}
                    src={require('../../images/newLogo.png')}
                    size='small'
                  />
                </Menu.Item>
              </Menu>
            </Container>
            <HomepageHeading props={this.props.props} mobile />
          </Segment>

          {children}
        </Sidebar.Pusher>
      </Responsive>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = (params, { children }) => (
  <div>
    {console.log(params.props.location.state.params)}
    <DesktopContainer props={params.props.location.state.params}>{children}</DesktopContainer>
    <MobileContainer props={params.props.location.state.params}>{children}</MobileContainer>
  </div>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

const BandCopyright = (props) => (
  <ResponsiveContainer textAlign='center' props={props}>
    <Segment inverted vertical>
    </Segment>
  </ResponsiveContainer>
)
export default BandCopyright;


const styles = {
  hero: {
    backgroundImage: 'linear-gradient(to bottom, rgba(20, 18, 37, 255), rgba(29, 12, 69, 255), rgba(38, 21, 89, 255), rgba(48, 24, 100, 255), rgba(75, 21, 109, 255), rgba(130, 9, 118, 255))',
    minHeight: 750,
    padding: '1em 0em'
  },
  heroMobile: {
    backgroundImage: 'linear-gradient(to bottom, rgba(20, 18, 37, 255), rgba(29, 12, 69, 255), rgba(38, 21, 89, 255), rgba(48, 24, 100, 255), rgba(75, 21, 109, 255), rgba(130, 9, 118, 255))',
    minHeight: 575,
    padding: '1em 0em'
  },
  swara_purple: {
    color: 'rgba(130, 9, 118, 255)',
  },
  band:{
    height: '100px',
    width: '100px',
  },
  cardSegment: {
    backgroundImage: 'linear-gradient(to bottom, rgba(20, 18, 37, 50), rgba(14, 15, 40, 50))',
  }
}
 
