import React, { Component } from 'react';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import { 
  Image, 
  Card, 
  Icon, 
  Button, 
  Form, 
  Segment, 
  Embed,
  Grid,
  Header,
  Table,
  Container,
  Divider,
  Feed,
} from 'semantic-ui-react';

import genres from '../const/genres';
import states from '../const/states';

import PlayerPlayer from '../backend/PlayerPlayer';
import Geocode from 'react-geocode';
import MapContainer from '../ui/MapContainer';



class PlayerCard extends Component {

  constructor(props) {
    super(props)

    //TODO:  Remove
    this.state ={
        genre: [],
        city: "",
        state: "",
        distance: 3000,
        song: {},
        band: {},
        startPlaying: false,
        lat: null,
        long: null,
        albumArtUrl: "SwaraCoverArt.png",
        reLoad: false,
        submittedState: {}
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handlePlayerPlayer = this.handlePlayerPlayer.bind(this)
    this.startGettingLocation()  
  }

  startGettingLocation() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // for when getting location is a success         
        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then (
          response => {
            var address 
            address = response.results[0].formatted_address;
            address = address.split(",")
            this.setState({city: address[1]})
            this.setState({lat: position.coords.latitude})
            this.setState({long: position.coords.longitude})
        
            var results = states.find(function(item){
              if(item.value.indexOf(address[2].substring(1,3))>-1){
                return (item.text)
              }
              return ""
            })
            this.setState({state: results.value})
          },
          error => {
            console.error(error)
          } 
        )
      },
     (error_message) => {
       // for when getting location results in an error
       console.error('An error has occured while retrieving location', error_message)
     } 
    )
   }
  

handlePlayerPlayer(playerInfo) {
  console.log("handleplayerplayer on playercard")
  console.log(playerInfo)
  this.setState({song: playerInfo.song})
  this.setState({band: playerInfo.band})
  this.setState({albumArtUrl: playerInfo.albumArtUrl})

}


handleChange = name => (e, { value }) => this.setState({ [name]: value });

handleSubmit(event) {
  event.preventDefault();
  if(this.state.genre !== this.state.submittedState.genre || this.state.city !== this.state.submittedState.city || 
      this.state.state !== this.state.submittedState.state || this.state.distance !== this.state.submittedState.distance) {
        this.setState({reLoad: true})
        this.setState({submittedState: this.state})
        console.log("handleSubmit")
        this.props.action(this.state)  //not sure why this is needed, but if removed app continues to look for song
        this.setState({startPlaying: true})
      }
}

render() {
  console.log(this.state)
  const {city, state, genre, distance} = this.state
  return (
    <Grid columns='equal' stackable>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Segment>
            <Embed active style={styles.map}>
              <MapContainer lat={this.state.lat} long={this.state.long}/>
            </Embed>  
          </Segment>   
         </Grid.Column>
         <Grid.Column width={6}>
          <Segment basic style={styles.search} verticalAlign='center'>
            <Flippy
              flipOnHover={false}
              flipOnClick={false} 
              flipDirection="vertical" 
              ref={(r) => this.flippyVertical = r} 
            >
            <FrontSide>
              <Header as='h3' icon inverted>
                <Icon name='search circle' />
                  <Header.Subheader>
                    Search for music near you!
                  </Header.Subheader>
              </Header> 
            <Form onSubmit={this.handleSubmit} size='tiny'>
              <Form.Group widths='equal'>
                <Form.Dropdown
                  placeholder='Select Genres'
                  fluid
                  multiple
                  search
                  selection
                  options={genres}
                  onChange={this.handleChange("genre")}
                  value={genre}
                />
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Input
                  width={8}
                  placeholder='City' 
                  onChange={this.handleChange("city")} 
                  value={city}
                />
                <Form.Dropdown 
                  placeholder='State' 
                  search 
                  selection 
                  options={states} 
                  onChange={this.handleChange("state")}
                  value={state}
                />
              </Form.Group> 
              <Form.Input 
                icon='map' 
                iconPosition='left' 
                placeholder='Distance' 
                onChange={this.handleChange("distance")} 
                value={distance} 
              />
                <Button 
                  fluid 
                  color='violet' 
                  size='medium' 
                  onClick={() => this.flippyVertical.toggle()}>Search</Button>
            </Form>
            </FrontSide>
            <BackSide>
              <Segment basic textAlign='center'>
              <Card.Content 
                  extra 
                  onClick={() => this.flippyVertical.toggle()}
                   >
                <Header as='h4' inverted>
                    <Icon name='info circle'/>Change Location or Genre
                </Header>
                </Card.Content>
                <a href= {this.state.band.web} target="_blank">
                  <Image 
                    //style={styles.card}
                    style={styles.albumArt}
                    // src={require('../../images/SwaraCoverArt.png')}
                    src = {this.state.albumArtUrl} 
                  />
                </a>
                {this.state.reLoad === false ? 
                  <PlayerPlayer 
                    city={this.state.city} 
                    state={this.state.state} 
                    distance={this.state.distance} 
                    genre={this.state.genre} 
                    startPlaying={this.state.startPlaying} 
                    action={this.handlePlayerPlayer}
                    reLoad ={this.state.reLoad}
                  /> : this.setState({reLoad: false})}
                <Card.Content 
                  extra 
                  style={{ marginTop: '0.5em' }} >
                  
                    <Header as='h4' inverted>
                      {(this.state.song.songName !== undefined) ? 
                      <div>SONG: {this.state.song.songName} <br></br>
                      ARTIST: {this.state.band.name} <br></br>
                      FROM: {this.state.band.city}, {this.state.band.state}</div>
                      :
                      "" }
                      </Header>
                </Card.Content>
              </Segment>
            </BackSide>
          </Flippy>
        </Segment> 
        </Grid.Column>
      </Grid.Row>

{/********* Placeholder code. Need to add band pic/bio and other songs. **********/}

  {/* <Divider />
      <Grid.Row columns={3}>
        <Grid.Column width={5}>
          <Segment inverted style={styles.cardSegment} textAlign='left'>
            <Image 
              src={require('../../images/GloeProfile.jpg')}
            />
            <Header as ='h1'>{this.state.band.name}</Header>
            <Header.Subheader>{this.state.band.city } {this.state.band.state}</Header.Subheader>
          </Segment>
        </Grid.Column>

        <Grid.Column width={6} style={styles.tableScroll}>
{this.state.song!==-1 ? 
          <Container>
            <Table basic='very' collapsing inverted verticalAlign='middle' width={6}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='3'>Now Playing on Swara</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell style={{"width": "10%"}}>
                    <Header as='h3' image>
                      <Image 
                        src={require('../../images/SwaraCoverArt.png')}
                        rounded
                      />
                    </Header>
                  </Table.Cell>
                  <Table.Cell inverted as='h3' style={{"width": "80%"}}>{this.state.song.songName}</Table.Cell>
                  <Table.Cell style={{"width": "10%"}}>
                    <Button basic circular size='tiny' color='violet' onClick={this.handleToggle} icon={this.state.playing===true? 'pause' : 'play'} style={{ marginTop: '1em' }}/>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Container>

              :"No Songs Found"}
                {console.log(this.state)}
               
      <Divider />

            <Table basic='very' collapsing inverted verticalAlign='middle'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='2'>Next on Swara</Table.HeaderCell>
                </Table.Row>
              </Table.Header> */}

{/********* Add scroll table below. **********/}
   
              {/* <Table.Body>
              <Table.Row>
                <Table.Cell style={{"width": "10%"}}>
                  <Header as='h3' image>
                    <Image 
                      src={require('../../images/SwaraCoverArt.png')}
                      rounded
                    />
                  </Header>
                </Table.Cell>
                <Table.Cell inverted as='h3' style={{"width": "90%"}}>Another Song Goes Here</Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell style={{"width": "10%"}}>
                  <Header as='h3' image>
                    <Image 
                      src={require('../../images/SwaraCoverArt.png')}
                      rounded
                    />
                  </Header>
                </Table.Cell>
                <Table.Cell inverted as='h3' style={{"width": "90%"}}>Another Song Goes Here</Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell style={{"width": "10%"}}>
                  <Header as='h3' image>
                    <Image 
                      src={require('../../images/SwaraCoverArt.png')}
                      rounded
                    />
                  </Header>
                </Table.Cell>
                <Table.Cell inverted as='h3' style={{"width": "90%"}}>Another Song Goes Here</Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell style={{"width": "10%"}}>
                  <Header as='h3' image>
                    <Image 
                      src={require('../../images/SwaraCoverArt.png')}
                      rounded
                    />
                  </Header>
                </Table.Cell>
                <Table.Cell inverted as='h3' style={{"width": "90%"}}>Another Song Goes Here</Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell style={{"width": "10%"}}>
                  <Header as='h3' image>
                    <Image 
                      src={require('../../images/SwaraCoverArt.png')}
                      rounded
                    />
                  </Header>
                </Table.Cell>
                <Table.Cell inverted as='h3' style={{"width": "90%"}}>Another Song Goes Here</Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell style={{"width": "10%"}}>
                  <Header as='h3' image>
                    <Image 
                      src={require('../../images/SwaraCoverArt.png')}
                      rounded
                    />
                  </Header>
                </Table.Cell>
                <Table.Cell inverted as='h3' style={{"width": "90%"}}>Another Song Goes Here</Table.Cell>
              </Table.Row>
              </Table.Body>
            </Table>



            </Grid.Column>
            
            <Card style={styles.cardScroll} color='purple'>
    <Card.Content inverted>
      <Card.Header style={styles.text}>Upcoming Shows</Card.Header>
    </Card.Content>
    <Card.Content>
      <Feed>
        <Feed.Event>
          <Feed.Content>
            <Feed.Date content='March 23, 2020' style={styles.text}/>
            <Feed.Date content='9:00 pm' style={styles.text}/>
            <Feed.Summary style={styles.text}>
              Whiskey Street, Salt Lake City
            </Feed.Summary>
          </Feed.Content>
        </Feed.Event>
<br />
        <Feed.Event>
          <Feed.Content>
            <Feed.Date content='April 15, 2020' style={styles.text} />
            <Feed.Date content='7:30 pm' style={styles.text} />
            <Feed.Summary style={styles.text}>
              Disco Lounge, Salt Lake City
            </Feed.Summary>
          </Feed.Content>
        </Feed.Event>
<br />
        <Feed.Event>
          <Feed.Content>
            <Feed.Date content='April 17, 2020' style={styles.text}/>
            <Feed.Date content='7:00 pm' style={styles.text}/>
            <Feed.Summary style={styles.text}>
              Whiskey Street, Salt Lake City
            </Feed.Summary>
          </Feed.Content>
        </Feed.Event>
<br />
        <Feed.Event>
          <Feed.Content>
            <Feed.Date content='April 17, 2020' style={styles.text}/>
            <Feed.Date content='7:00 pm' style={styles.text}/>
            <Feed.Summary style={styles.text}>
              Whiskey Street, Salt Lake City
            </Feed.Summary>
          </Feed.Content>
        </Feed.Event>
<br />
        <Feed.Event>
          <Feed.Content>
            <Feed.Date content='April 17, 2020' style={styles.text}/>
            <Feed.Date content='7:00 pm' style={styles.text}/>
            <Feed.Summary style={styles.text}>
              Whiskey Street, Salt Lake City
            </Feed.Summary>
          </Feed.Content>
        </Feed.Event>
<br />
        <Feed.Event>
          <Feed.Content>
            <Feed.Date content='April 17, 2020' style={styles.text}/>
            <Feed.Date content='7:00 pm' style={styles.text}/>
            <Feed.Summary style={styles.text}>
              Whiskey Street, Salt Lake City
            </Feed.Summary>
          </Feed.Content>
        </Feed.Event>
      </Feed>
    </Card.Content>
  </Card> */}

{/********* End scroll code. **********/}

          {/* </Grid.Row> */}
          <Grid.Row>
        <Grid.Column width={16}>
        <Segment style={styles.cardSegment}>
          </Segment> 
        </Grid.Column>
      </Grid.Row>

{/********* End Placeholder code. **********/}

    </Grid>
  )
}}

export default PlayerCard;

const styles = {
  card: {
    width: '300px',
  },
  albumArt: {
    height: '250px',
  },
  map: {
    minHeight: '425px',
  },
  search: {
    minHeight: '425px',
  },
  cardSegment: {
    backgroundImage: 'linear-gradient(to bottom, rgba(20, 18, 37, 50), rgba(14, 15, 40, 50))',
  },
  hero: {
    minHeight: '250px',
  },
  text: {
    color: 'white',
  },
  tableScroll: {
    maxHeight: '375px',
    overflowY: 'auto',
   },
   cardScroll: {
    maxHeight: '375px',
    overflowY: 'auto',
    backgroundImage: 'linear-gradient(to bottom, rgba(20, 18, 37, 50), rgba(14, 15, 40, 50))',

   },


  // hero: {
  //   backgroundImage: `url(${Hero})`,
  //   backgroundSize: 'cover',
  //   minHeight: '435px',
  // },  
}



