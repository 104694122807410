import React, { Component } from 'react';
import { 
  Button, 
  Modal, 
  Header, 
  Image, 
  List, 
} from 'semantic-ui-react';

class Copyright extends Component {
  state = { modalOpen: false }

  handleOpen = () => this.setState({ modalOpen: true })
  handleClose = () => this.setState({ modalOpen: false })   
    
  render() {
    return (
      <Modal
        trigger={<List.Item as='a'onClick={this.handleOpen}>Copyright Policy</List.Item>}
        open={this.state.modalOpen}
        onClose={this.handleClose}>
          <Modal.Content image>
            <Image 
              wrapped 
              size='massive'
              src={require('../../images/Copyright.jpg')} 
            />
            <Modal.Description>
              <Header as='h1' style={styles.swara_purple}>Copyright Policy</Header>
                <Header as='h3'>
                  Music uploaded to this site must be of original works by the user.  By uploading the music, 
                  the user is allowing Swara to stream the music to users and declaring that the music is an original work.  
                  Music reported to not be of original work of the user will be promptly removed from the site.  
                </Header>
            </Modal.Description>
          </Modal.Content>
        <Modal.Actions>
          <Button style={styles.swara_purple} onClick={this.handleClose} inverted>Home</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default Copyright;

const styles = {
      swara_purple: {
        color: 'rgba(130, 9, 118, 255)',
      }
    }