import React, { Component } from 'react';
import { 
  Button, 
  Modal, 
  Popup
} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

import {database, storage} from '../backend/config';

class AlbumDelete extends Component {

     state = {}

  state = { modalOpen: false }

  handleOpen = () => this.setState({ modalOpen: true })
  handleClose = () => this.setState({ modalOpen: false })   

  startRemovingAlbum(id) {
      console.log("REMOVING ALBUM: " + id)
      const updatesAlbum = {
          [`albums/${id}`]: null
      }
    
      database.ref().update(updatesAlbum).then(() => {
        database.ref('songs').orderByChild('albId').equalTo(id).once("value", snapshot => {
            let songs=[]
            snapshot.forEach(data => {
              songs.push(data.toJSON())
              console.log(data.toJSON())
              console.log(data.key)
              const updatesSong = {
                  [`songs/${data.key}`]: null,
              }
              
              database.ref().update(updatesSong).then(() => {
                  storage.ref(`musicFiles/${data.key}.mp3`).delete()            
                  database.ref().update(updatesSong)
              }).catch((error) => {
                  console.log(error)
              })
            })
        }).catch((error) => {
            console.log(error)
        })
        storage.ref(`albumArt/${id}.jpg`).delete()
      }).catch((error) => {
        console.log(error)
      })
    }
    
  render() {
    return (
      <Modal 
        size='mini' 
        closeIcon
        trigger={<Popup
          trigger={<Button circular icon='delete' floated='left' color='violet' onClick={this.handleOpen}/>}
          content='Delete Album'
          hideOnScroll
          style={styles.pop}
          inverted 
        />
        
        }
      
        open={this.state.modalOpen}
        onClose={this.handleClose}>
          
          <Modal.Header>Delete Album</Modal.Header>
              <Modal.Content>
                <p>This album rocks! Are you sure you want to delete?</p>
              </Modal.Content>
                <Modal.Actions>
                  <Button style={styles.swara_purple} content='BYE!' onClick={() =>{this.startRemovingAlbum(this.props.props)}} as={Link} to='/bands' />
                </Modal.Actions>
      </Modal>
    );
  }
}

export default AlbumDelete;

const styles = {
      swara_purple: {
        color: 'rgba(130, 9, 118, 255)',
      },
      pop: {
        borderRadius: 5,
        opacity: .96,
        padding: '1.5em',
     },
    }