import PropTypes from 'prop-types';
import React, { Component } from 'react';
import About from '../modals/About';
import Copyright from '../modals/Copyright';
import Report from '../modals/Report';
import Contact from '../modals/Contact';
import { Link } from 'react-router-dom';
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Table,
  Visibility,
} from 'semantic-ui-react';
import PlayerPlayer from '../backend/PlayerPlayer';
import genres from '../const/genres';



const getWidth = () => {
  const isSSR = typeof window === 'undefined'

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}

var fullGenres=[]
genres.forEach(element => {
  fullGenres.push(element.value)
});


const HomepageHeading = ({ mobile }) => (
  <Container text>
    <Header
      as='h1'
      content='live. local. music.'
      inverted
      style={{
        fontSize: mobile ? '2.5em' : '4em',
        fontWeight: 'normal',
        marginBottom: 0,
        marginTop: mobile ? '1.5em' : '2em',
      }}
    />
    <Header
      content='find new music to love in the city you love.'
      inverted
      style={{
        fontSize: mobile ? '1.2em' : '1.7em',
        fontWeight: 'normal',
        marginTop: mobile ? '0.5em' : '.10em',
      }}
    />
    <Button as={ Link } to='/player' color="purple" size='large'>
      Start Listening
      <Icon name='right arrow' />
    </Button>

  {/* <div style={styles.down} /> */}

  </Container>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}


class DesktopContainer extends Component {

  constructor() {
    super()
    this.state ={}
  }
 

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  render() {
    const { children } = this.props
    const { fixed } = this.state

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            textAlign='center'
            style={styles.hero}
            vertical
          >
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              secondary={!fixed}
              size='large'
            >
              <Container>
                <Menu.Item as='a'>
                  <Image
                    src={require('../../images/newLogo.png')}
                    size='medium'
                  />
                </Menu.Item>
                <Menu.Item>Beta V2</Menu.Item>
                <Menu.Item position='right'>
                  <Button as={ Link } to='/bands' inverted={!fixed} color='purple'>
                    My Bands
                  </Button>
                  <Button as={ Link } to='/signup' inverted={!fixed} color='purple' style={{ marginLeft: '0.5em' }}>
                    Sign Up
                  </Button>
                </Menu.Item>
              </Container>
            </Menu>
            <HomepageHeading />
          </Segment>
        </Visibility>

        {children}
      </Responsive>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}



 

class MobileContainer extends Component {
  constructor() {
    super()
    this.state ={}


  }

  handleSidebarHide = () => this.setState({ sidebarOpened: false })
  handleToggle = () => this.setState({ sidebarOpened: true })


  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          as={Menu}
          animation='push'
          inverted
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
        >
          <Menu.Item as={ Link } to="/">Home</Menu.Item>
          <Menu.Item as={ Link } to="/player">Listen</Menu.Item>
          <Menu.Item as={ Link } to="/bands">Band Page</Menu.Item>
          <Menu.Item as='a'>Log in</Menu.Item>
          <Menu.Item as='a'>Sign Up</Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            textAlign='center'
            style={styles.heroMobile}
            vertical
          >
            <Container>
              <Menu inverted pointing secondary size='large'>
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name='sidebar' />
                </Menu.Item>
                <Menu.Item position='right'>
                  <Button as={ Link } to="/bands" inverted color='purple'>
                    Log in
                  </Button>
                  <Button as={ Link } to="/login" inverted color='purple'>
                    Sign Up
                  </Button>
                </Menu.Item>
              </Menu>
            </Container>
            <HomepageHeading mobile />
          </Segment>

          {children}
        </Sidebar.Pusher>
      </Responsive>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
)



ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

class Home extends Component {
//const Home = () => (

  constructor() {
    super()
    this.state ={
      song: {},
      band: {},
      albumArtUrl: 'SwaraCoverArt.png'
    }

    this.handlePlayerPlayer=this.handlePlayerPlayer.bind(this)
  }


  handlePlayerPlayer(playerInfo) {
    this.setState({albumArtUrl: playerInfo.albumArtUrl})
    this.setState({song: playerInfo.song,
      songInfo: playerInfo.songInfo,
      band: playerInfo.band})
    console.log(playerInfo)
  }

render() {
  return (
  <ResponsiveContainer>
    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <Container>
        <Grid divided inverted stackable reversed='computer tablet'>
          <Grid.Row columns={3}>
            <Grid.Column width={13}>
            <Table basic='very' collapsing inverted columns={5}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Now Playing on Swara</Table.HeaderCell>
                  <Table.HeaderCell />
                  <Table.HeaderCell />
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <a href= {this.state.band.web} target="_blank">
                      <Header image>
                        <Image 
                          src ={(this.state.albumArtUrl)}
                          style={styles.band}
                        />
                     </Header>
                     </a>
                  </Table.Cell>
                  <Table.Cell>
                    <Header.Content as='h3' textalign='right'>
                     {this.state.songInfo!==undefined?this.state.songInfo.songName:""} by {this.state.band!==undefined?this.state.band.name:""}
                    </Header.Content>
                  </Table.Cell>
                  <Table.Cell>
                    <Header.Subheader as='h5'textalign='left'>
                    {this.state.band.city!==undefined?this.state.band.city:""}, {this.state.band.state!==undefined?this.state.band.state:""} 
                    </Header.Subheader>
                  </Table.Cell>
                  <Table.Cell>
                    <Header.Subheader as='h5'textalign='right'>
                      <List>
                        {/* <List.Item><Report /></List.Item> */}
                      </List>
                    </Header.Subheader>
                  </Table.Cell>
                  <Table.Cell textalign='right'>
                    <PlayerPlayer 
                      random = {true}
                      startPlaying={true} 
                      action={this.handlePlayerPlayer}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
              </Table>
            </Grid.Column>

            <Grid.Column width={3}>
              <Header inverted as='h3' content='Info' />
              <List link inverted>
                <List.Item><About /></List.Item>
                <List.Item><Contact /></List.Item>
                <List.Item><Copyright /></List.Item>
                {/* <List.Item as='a'>Privacy</List.Item> */}
                <List.Item href='https://www.facebook.com'>
                  <Icon name='facebook' />
                   Be Social - Follow Us
                </List.Item>
              </List>
            </Grid.Column>

          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </ResponsiveContainer>
)
}
}

export default Home;


const styles = {
  hero: {
    backgroundImage: 'linear-gradient(to bottom, rgba(20, 18, 37, 255), rgba(29, 12, 69, 255), rgba(38, 21, 89, 255), rgba(48, 24, 100, 255), rgba(75, 21, 109, 255), rgba(130, 9, 118, 255))',
    minHeight: 700,
    padding: '1em 0em'
  },
  heroMobile: {
    backgroundImage: 'linear-gradient(to bottom, rgba(20, 18, 37, 255), rgba(29, 12, 69, 255), rgba(38, 21, 89, 255), rgba(48, 24, 100, 255), rgba(75, 21, 109, 255), rgba(130, 9, 118, 255))',
    minHeight: 350,
    padding: '1em 0em'
  },
  swara_purple: {
    color: 'rgba(130, 9, 118, 255)',
  },
  band:{
    height: '100px',
    width: '100px',
  },
  // down: {
  //   height: '50px',
  //   width: '50px',  
  //   backgroundImage: `url(${Down})`,
  //   backgroundRepeat: 'no-repeat',
  //   backgroundSize: 'contain',
  //   animation: 'pulse 3s infinite',
  //   display: 'flex',
  //   justifyContent: 'center',
  //   position: 'absolute',
  //   bottom: '0px',
  // },
}
 