import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Button, Form, Segment, Grid, Header, Icon } from 'semantic-ui-react';


class AlbumEdit extends Component {
  
render() {
  return(
    <Segment placeholder color='purple'>
      <Grid columns={2} stackable textAlign='center'>
        <Grid.Row>
          <Header icon>
            <Icon name='microphone' style={{ marginTop: '0.4em' }}  />
              Edit Album Name
          </Header>
        </Grid.Row>
        <Form>
          <Form.Input focus placeholder='Album Name' />    
           <Form.Input focus placeholder='Cover Art' />          
          <Button.Group>
            <Button color='purple' as={Link} to='/bands'>Update</Button>
          <Button.Or />
            <Button color='purple' as={Link} to='/songs'>Cancel</Button>
          </Button.Group>   
        </Form>
      </Grid>
        <br />
    </Segment>
  )
}}

export default AlbumEdit;