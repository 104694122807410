import React, { Component } from 'react';
import { Map, GoogleApiWrapper } from 'google-maps-react';


export class MapContainer extends Component {

//Commented out to clear a warning (useless constructor). Delete after verifying it works.

// constructor(props) {
//   super(props);
// }



render() {
    return (
        <Map
          google={this.props.google}
          zoom={10}
          initialCenter={{ lat: this.props.lat, lng: this.props.long}}
          center={{ lat: this.props.lat, lng: this.props.long}}
        />
    ); 
  }
}


  export default GoogleApiWrapper(
    (props) => ({
    apiKey: 'AIzaSyBnMtvCUtzGfFY_8_nihrnOP3F9QM0TQ9Y'
    }
  ))(MapContainer); 