import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Grid,
  Icon,
  Image,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react';

import Login from '../auth/Login';


const getWidth = () => {
  const isSSR = typeof window === 'undefined'

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}


const HomepageHeading = ({ mobile }) => (
<Container>
  <Grid columns='equal' stackable verticalAlign='middle'>
   <Grid.Row textAlign='center'>
     <Grid.Column>
       <Segment basic />
     </Grid.Column>
     <Grid.Column width={6}>
       <Segment style={styles.cardSegment} textAlign='center'>

         <Login/>

       </Segment>
     </Grid.Column>
     <Grid.Column>
      <Segment basic />
     </Grid.Column>
   </Grid.Row>
  </Grid>
</Container>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}

class DesktopContainer extends Component {
  state = {}

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })
  

  render() {
    const { children } = this.props
    const { fixed } = this.state

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            textAlign='center'
            style={styles.hero}
            vertical
          >
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              secondary={!fixed}
              size='large'
            >
              <Container>
                <Menu.Item as={ Link } to='/'>
                  <Image
                    src={require('../../images/newLogo.png')}
                    size='medium'
                  />
                </Menu.Item>
                <Menu.Item>Beta V2</Menu.Item>
              </Container>
            </Menu>
            <HomepageHeading />
          </Segment>
        </Visibility>

        {children}
      </Responsive>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })
  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          as={Menu}
          animation='push'
          inverted
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
        >
          <Menu.Item as={ Link } to="/">Home</Menu.Item>
          <Menu.Item as={ Link } to="/player">Listen</Menu.Item>
          <Menu.Item as='a'>Band Page</Menu.Item>
          <Menu.Item as={ Link } to="/bands">Log in</Menu.Item>
          <Menu.Item as='a'>Sign Up</Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            textAlign='center'
            style={styles.heroMobile}
            vertical
          >
            <Container>
              <Menu inverted pointing secondary size='large'>
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name='sidebar' />
                </Menu.Item>
                <Menu.Item as={ Link } to='/' position='right'>
                  <Image
                    style={{ marginLeft: '0.5em' }}
                    src={require('../../images/newLogo.png')}
                    size='small'
                  />
                </Menu.Item>
              </Menu>
            </Container>
            <HomepageHeading mobile />
          </Segment>

          {children}
        </Sidebar.Pusher>
      </Responsive>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

const LoginUi = () => (
  <ResponsiveContainer textAlign='center'>
    <Segment vertical style={styles.bottom}>
    </Segment>  
  </ResponsiveContainer>
)
export default LoginUi;


const styles = {
  hero: {
    backgroundImage: 'linear-gradient(to bottom, rgba(20, 18, 37, 255), rgba(29, 12, 69, 255), rgba(38, 21, 89, 255), rgba(48, 24, 100, 255), rgba(75, 21, 109, 255), rgba(130, 9, 118, 255))',
    backgroundSize: 'cover !important',
    height: '100vh',
    width: '100%'
  },
  bottom: {
    background: 'rgba(130, 9, 118, 255)',
    padding: '5em 0em',
    marginTop: '-1px',
  },
  heroMobile: {
    backgroundImage: 'linear-gradient(to bottom, rgba(20, 18, 37, 255), rgba(29, 12, 69, 255), rgba(38, 21, 89, 255), rgba(48, 24, 100, 255), rgba(75, 21, 109, 255), rgba(130, 9, 118, 255))',
    minHeight: 575,
    padding: '1em 0em'
  },
  swara_purple: {
    color: 'rgba(130, 9, 118, 255)',
  },
  band:{
    height: '100px',
    width: '100px',
  },
  cardSegment: {
    minHeight: 350,
    backgroundImage: 'linear-gradient(to bottom, rgba(20, 18, 37, 50), rgba(14, 15, 40, 50))',
    }
  }
