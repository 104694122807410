import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { 
  Image, 
  Card, 
  Grid,
  Button,
  Popup,
  Dropdown,
  Header,
  Icon,
  Modal,
} from 'semantic-ui-react';
import {database, storage} from '../backend/config.js';






class ArtCard extends Component {

  constructor(props){
    super(props)

    this.state={
      bands: null,
      bandNamesArray: [],
      albums: [],
      // selectedBandName: "My Bands"
    }
  
    if(props.props.location.state !==undefined) {
      console.log(props.props.location.state.selectedBand)
      this.setState({storedId: props.props.location.state.selectedBand})
    }

    this.startGettingBands = this.startGettingBands.bind(this)
    this.startGettingAlbums = this.startGettingAlbums.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.updateSelectedBandName = this.updateSelectedBandName.bind(this)
    this.startRemovingBand = this.startRemovingBand.bind(this)
    this.startRemovingAllAlbums = this.startRemovingAllAlbums.bind(this)
    
  }

  componentDidMount() {
    console.log(this.props.props)
    this.startGettingBands(this.props.props.uid)
  }

  updateSelectedBandName(bandArray, bandId) {
    console.log("updateSelectedBandName: " + bandId)
    console.log(this.state.selectedBandName)
    console.log(bandArray.length)
    this.setState({selectedId: bandId})
    bandArray.forEach(data => {
      console.log(data.key)
      if(data.key === bandId) {
        console.log(data)
        this.setState({selectedBandName: data.text})
      }
    })
  }

  startGettingBands(userId){
    database.ref('bands').orderByChild('userID').equalTo(userId).on("value", snapshot => {
    let bands=[]
    let bandNamesArray=[]
    let count=1
    snapshot.forEach(function(data) {
      let dataJSON = data.toJSON()
      dataJSON.key = data.key
      bands.push(dataJSON)
      bandNamesArray.push({text: data.toJSON().name, key: data.key, value: count})
      count++
    })
    this.setState({bands:bands})
    this.setState({bandNamesArray: bandNamesArray})
    console.log(bands)
    console.log(bandNamesArray)

    if(bands.length>0) {
      var bandSelected = bands[0].key

    }

    if(bands.length===0)
      this.setState({selectedBandName: "My Bands"})

    if(this.props.props.location.state !==undefined) {
      console.log(this.props.props.location.state.selectedBand)
      bandSelected = this.props.props.location.state.selectedBand
    }

    this.startGettingAlbums(bandSelected) 
    this.setState({selectedId: bandSelected})
    console.log(bandNamesArray)
    console.log(bandSelected)
    this.updateSelectedBandName(bandNamesArray, bandSelected)
    })  
  }

  startGettingAlbums(bandId){
    if(bandId === undefined)
      return
    console.log("starting to get band albums " + bandId)
    database.ref('albums').orderByChild('bndId').equalTo(bandId).once("value", snapshot =>{
      console.log(snapshot.toJSON())
      let albums=[]
      this.setState({albums: albums})
      snapshot.forEach(data => {
        storage.ref(`albumArt/${data.key}.jpg`).getDownloadURL().then(httpUrl => {
          let dataJSON = data.toJSON()
          dataJSON.albumArtUrl= httpUrl
          dataJSON.key = data.key
          albums.push(dataJSON)
          this.setState({albums: albums})
        }
        ).catch(error => {
          let dataJSON = data.toJSON()
          dataJSON.albumArtUrl= 'SwaraCoverArt.png'
          dataJSON.key = data.key
          albums.push(dataJSON)
          this.setState({albums: albums})
        })  
      })
    })      
  }

startRemovingBand(bandId){
  this.setState({ modalOpen: false})
  console.log("startRemovingBand " + bandId)

  this.startRemovingAllAlbums(bandId)

  const updates = {
      [`bands/${bandId}`]: null,
      [`location/${bandId}`]: null
      }

  database.ref().update(updates).then(() => {
    this.startGettingBands(this.props.props.uid)
    }).catch((error) => {
        console.log(error)
    })

}


startRemovingAllAlbums(bandId) {
  console.log("startRemovingAllAlbums Entered " + bandId)
      //get all albums with bandId
      database.ref('albums').orderByChild('bndId').equalTo(bandId).once("value", snapshot => {
          let albums=[]
          snapshot.forEach(function(data) {
              albums.push(data.toJSON())
              console.log("all album snapshot" +data.key)

              var updates = {
                  [`albums/${data.key}`]: null
              }
              console.log(updates)
              storage.ref(`albumArt/${data.key}.jpg`).delete()
              
              //remove album and delete all songs associated with the album
              database.ref().update(updates).then(() => {
                   database.ref('songs').orderByChild('bndId').equalTo(bandId).once("value", snapshot => {
                      let songs=[]
                      snapshot.forEach(function(data) {
                          songs.push(data.toJSON())
                          console.log("songs snapshot" +data.key)

                          updates = {
                              [`songs/${data.key}`]: null,
                          }
                          
                          console.log(data.key)
                      
                          database.ref().update(updates).then(() => {
                              storage.ref(`musicFiles/${data.key}.mp3`).delete()
                              database.ref().update(updates)
                              console.log("song removed")
                          }).catch((error) => {
                              console.log(error)
                          })   
                      })
                  }).catch((error) => {
                      console.log(error)
                  })
              })
          })
          
      }).catch((error) => {
          console.log(error)
      })
  
}


handleChange(e, value) {
  console.log("selectedId: " + this.state.selectedId)
  console.log(this.state.bandNamesArray[value.value-1].key)
  this.startGettingAlbums(this.state.bandNamesArray[value.value-1].key)
  this.setState({selectedId: this.state.bandNamesArray[value.value-1].key})
  this.updateSelectedBandName(this.state.bands, this.state.bandNamesArray[value.value-1].key)
}


state = { modalOpen: false }

  handleOpen = () => this.setState({ modalOpen: true })
  handleClose = () => this.setState({ modalOpen: false })




render() {
  console.log(this.state.bands)
  if(this.state.bands!==null && this.state.bands.length>0) {
  return (
    <Grid stackable>

    {/* Code for band name and buttons */}
    {console.log(this.state)}
      <Grid.Row columns={5}>
        <Grid.Column width={4} verticalAlign='middle'>
          <Dropdown as='h3' text={this.state.selectedBandName} options ={this.state.bandNamesArray} onChange={this.handleChange} />
        </Grid.Column>
        <Grid.Column width={3} verticalAlign='middle'>
        <Link to ={{
            pathname: '/new_band',
            state: {
              params: {uid: this.props.props.uid,
                        bandId: null}
            }
          }}>
            <Popup
              trigger={<Button circular icon='add' color='violet' />}
              content='Add New Band'
              hideOnScroll
              style={styles.pop}
              inverted
            
            />
        </Link>          

        <Link to ={{
            pathname: '/new_band',
            state: {
              params: {uid: this.props.props.uid,
                        bandId: this.state.selectedId}
            }
          }}>
            <Popup
              trigger={<Button circular icon='edit' color='violet' />}
              content='Edit Band'
              hideOnScroll
              style={styles.pop}
              inverted
            />
        </Link>


        <Modal 
          size='mini' 
          closeIcon
          trigger={<Popup
                     trigger={<Button circular icon='delete' color='violet' onClick={this.handleOpen}/>}
                     content='Delete Band'
                     hideOnScroll
                     style={styles.pop}
                     inverted 
                    />
                  }
          open={this.state.modalOpen}
          onClose={this.handleClose}>
          
          <Modal.Header>You're breaking up the band?!</Modal.Header>
              <Modal.Content>
                <p>Are you sure you want to delete?</p>
              </Modal.Content>
              <Link to='/bands'>
                <Modal.Actions>
                  <Button 
                    style={styles.swara_purple} 
                    content='BYE!' 
                    onClick={() => this.startRemovingBand(this.state.selectedId)} 
                    floated='right'
                  />
                </Modal.Actions>
              </Link>
      </Modal>


  
          {/* <Popup
            trigger={<Button circular icon='delete' color='violet' onClick={() => this.startRemovingBand(this.state.selectedId)}/>}
            content='Delete Band'
            hideOnScroll
            style={styles.pop}
            inverted
          /> */}







        </Grid.Column>
        {/* <Grid.Column verticalAlign='middle' width={2}>
          <Header as='h3' inverted>Next Gig:</Header>
        </Grid.Column>
        <Grid.Column verticalAlign='middle' width={4}>
          <Header as='h3' inverted 
            text={this.state.selectedBandName} 
            options ={this.state.bandNamesArray} 
            onChange={this.handleChange}
          >March 20, 2020</Header>
        </Grid.Column>
        <Grid.Column width={2} verticalAlign='middle'>

        <Link to ={{
            pathname: '/gigs',
            state: {
              params: {uid: this.props.props.uid,
                        bandId: this.state.selectedId}
            }
          }}>
          <Popup
            trigger={<Button circular icon='calendar plus outline' color='violet' />}
            content='Add New Gig'
            hideOnScroll
            style={styles.pop}
            inverted
          />
          </Link>
        </Grid.Column> */}
      </Grid.Row>


      <Grid.Row columns={4} style={styles.tableScroll}>

        {this.state.albums.length>0 ? this.state.albums.map((singleName,index) =>
                // <Dropdown.Item key={index} text ={singleName}/>

        <Grid.Column key={index}>
     
              <Card style={styles.card}>
                <Link to = {{
                    pathname: '/songs',
                    state: {
                      params: singleName
                    }
                }}>
                <Icon.Group size='huge'>
                <Image style={styles.card}
                  src={(singleName.albumArtUrl)}
                />
                <Icon corner name='add' color='black'/>
                </Icon.Group>
                </Link>
                <Card.Content>
                  <Card.Description style={styles.text}>{singleName.albnm}</Card.Description>
                </Card.Content>
              </Card>          
              <br />
              <br />
        </Grid.Column>
 ): ""}




  {/* Default cover art.*/}

        <Grid.Column>
              <Card style={styles.card}>
                <Link to = {{
                  pathname: '/bandcopyright',
                  state: {
                   // params: {bandId: this.state.selectedId}
                      params: {albums: {bndId: this.state.selectedId}}
                  }
                }}>
                <Image 
                {...console.log(this.state.selectedId)}
                  style={styles.card} 
                  src={require('../../images/album.png')} 
                />

                </Link>
              </Card>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )//end of return
  }
  else if (this.state.bands!==null && this.state.bands!==undefined) {
    console.log(this.state.bands)
    console.log("sending to /new_bands")
    return (
    <Redirect to = {{
      pathname: '/new_band',
      state: {
        params: {uid: this.props.props.uid,
                  bandId: "none"}
      }
    }}/>
    )
  }
  else {
    console.log(this.state.bands)
    return ("loading")
  }
}
}

export default ArtCard;

const styles = {
  card: {
    width: '150px',
    height: '150px'
  },
  text: {
    color: 'white'
  },
  pop: {
    borderRadius: 5,
    opacity: .96,
    padding: '1.5em',
  },
  tableScroll: {
    maxHeight: '33em',
    overflow: 'auto',
    padding: '3.5em 3.5em',
   },
  swara_purple: {
    color: 'rgba(130, 9, 118, 255)',
    marginRight: '1em',
    marginBottom: '.5em',
  },
}


