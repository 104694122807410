import React, { useCallback, useContext } from "react";
import { withRouter, Redirect } from "react-router";
import firebase from "../backend/config";
import {
  Grid,
  Form,
  Segment,
  Button,
  Header,
  Icon,
  Divider,
} from "semantic-ui-react";

import { AuthContext } from "./Auth";
import { auth, provider} from '../backend/config'

var loggedIn = false

const Login = ({ history }) => {
  const handleLogin = useCallback(
      async event => {
        event.preventDefault();  
        const { email, password } = event.target.elements;
        try {
          const user = await firebase
            .auth()
            .signInWithEmailAndPassword(email.value, password.value);
          loggedIn = true
      } catch (error) {
        alert(error);
      }
      },
      [history]
  );



  const loginWProvider = ({ history }) => {
     auth.signInWithPopup(provider).then((result) => {
            // history.push("/new_band")
            loggedIn = true
        }).catch(function(error) {
            console.log(error.message)
        })
    
}

  const { currentUser } = useContext(AuthContext)

  if (loggedIn) {
    return <Redirect to ="/bands"/>
  }

  if (currentUser) {
        return <Redirect to="/" />;
  }


  return (
    <div>
      <Grid textAlign="center" verticalAlign="middle">
        <Grid.Column style={{ maxWidth: 450 }}>
          <Form onSubmit={handleLogin} size="large">
            <Segment raised>
            <Header as='h3'>Login With Email To Rock!</Header>
              <Form.Input
                fluid
                name="email"
                icon="mail"
                iconPosition="left"
                placeholder="Email Address"
                type="email"
              />

              <Form.Input
                fluid
                name="password"
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
              />
     
              <Button 
          
                color="violet"
                fluid
                size="large"
              >
               Sign In
              </Button>
            </Segment>
          </Form>

          <Divider horizontal inverted>Or</Divider>

          <Form onSubmit={loginWProvider} size="large">
            <Segment raised>
              <Button color="google plus" fluid size="large" icon labelPosition='left'>
                <Icon name='google' />
                  Sign In With Google
              </Button>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>

      </div>
    );
  };
  
  export default withRouter(Login);
