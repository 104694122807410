import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Container,
  Grid,
  Icon,
  Image,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react';

import SongViewCard from '../cards/SongViewCard';


const getWidth = () => {
  const isSSR = typeof window === 'undefined'

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}


const HomepageHeading = (props, { mobile }) => (
<Container>
  <Grid columns='equal' stackable verticalAlign='middle'>
    <Grid.Row>
      <Grid.Column>
        <Segment basic />
      </Grid.Column>
      <Grid.Column width={12}>
        <Segment style={styles.cardSegment}>
          <SongViewCard props={props.props}/>
        </Segment>
      </Grid.Column>
      <Grid.Column>
        <Segment basic />
      </Grid.Column>
     </Grid.Row>
  </Grid>
</Container>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}

class DesktopContainer extends Component {

  constructor(props) {
    super(props)
    console.log(props.props)

  }

  state = {}

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })
  

  render() {
    const { children } = this.props
    const { fixed } = this.state

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >

          <Container
            textAlign='center'
            style={styles.hero}
            vertical
          
          >

            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              secondary={!fixed}
              size='large'
            >
              <Container>
                <Menu.Item as={ Link } to='/'>
                  <Image
                    src={require('../../images/newLogo.png')}
                    size='medium'
                  />
                </Menu.Item>
                <Menu.Item>Beta V2</Menu.Item>
                <Menu.Item position='right'>
                  <Button as='a' inverted={!fixed} color='purple' style={{ marginLeft: '0.5em' }}>
                    Logout
                  </Button>
                </Menu.Item>
              </Container>
            </Menu>
            <HomepageHeading props={this.props.props}/>

          </Container>

        </Visibility>

        {children}
      </Responsive>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {

  constructor(props) {
    super(props)
    console.log(props.props)

  }

  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })
  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          as={Menu}
          animation='push'
          inverted
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
        >
          <Menu.Item as={ Link } to="/">Home</Menu.Item>
          <Menu.Item as={ Link } to="/player">Listen</Menu.Item>
          <Menu.Item as={ Link } to="/bands">Band Page</Menu.Item>
          <Menu.Item as='a'>Log in</Menu.Item>
          <Menu.Item as='a'>Sign Up</Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            textAlign='center'
            style={styles.heroMobile}
            vertical
          >
            <Container>
              <Menu inverted pointing secondary size='large'>
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name='sidebar' />
                </Menu.Item>
                <Menu.Item position='right'>
                  <Button as={ Link } to="/bands" inverted color='purple'>
                    Log in
                  </Button>
                  <Button as='a' inverted color='purple' style={{ marginLeft: '0.5em' }}>
                    Sign Up
                  </Button>
                </Menu.Item>
              </Menu>
            </Container>
            <HomepageHeading props={this.props.props} mobile />
          </Segment>

          {children}
        </Sidebar.Pusher>
      </Responsive>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = (params, { children }) => (
  <div>
    {console.log(params.props.location.state.params)}
    <DesktopContainer props={params.props.location.state.params}>{children}</DesktopContainer>
    <MobileContainer props={params.props.location.state.params}>{children}</MobileContainer>
  </div>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

const SongView = (props) => (
  <ResponsiveContainer textAlign='center' props={props} {...console.log(props)}>
    <Segment inverted vertical>
    </Segment>
  </ResponsiveContainer>
)
export default SongView;


const styles = {

  //Change the segment above to Container and make hero look like below.

  hero: {
    backgroundImage: 'linear-gradient(to bottom, rgba(20, 18, 37, 255), rgba(29, 12, 69, 255), rgba(38, 21, 89, 255), rgba(48, 24, 100, 255), rgba(75, 21, 109, 255), rgba(130, 9, 118, 255))',
    backgroundSize: 'cover !important',
    height: '100vh',
    width: '100%'
  },
  heroMobile: {
    backgroundImage: 'linear-gradient(to bottom, rgba(20, 18, 37, 255), rgba(29, 12, 69, 255), rgba(38, 21, 89, 255), rgba(48, 24, 100, 255), rgba(75, 21, 109, 255), rgba(130, 9, 118, 255))',
    minHeight: 575,
    padding: '1em 0em'
  },
  swara_purple: {
    color: 'rgba(130, 9, 118, 255)',
  },
  band:{
    height: '100px',
    width: '100px',
  },
  cardSegment: {
    backgroundImage: 'linear-gradient(to bottom, rgba(20, 18, 37, 50), rgba(14, 15, 40, 50))',
  }
}
 
