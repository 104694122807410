import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Grid,
  Icon,
  Image,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
  Button
} from 'semantic-ui-react';

import ArtCard from '../cards/ArtCard';
import {auth} from '../backend/config';


const getWidth = () => {
  const isSSR = typeof window === 'undefined'

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}


const HomepageHeading = (props, { mobile }) => (
<Container>
  <Grid>
      {console.log(props)}
      <Grid.Column>
        <Segment inverted style={styles.cardSegment}>
        <Grid.Row>
          <ArtCard props={props.props}/>
        </Grid.Row>
      </Segment>
    </Grid.Column>
  </Grid>
</Container>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}


class DesktopContainer extends Component {

  constructor(props) {
    super(props)
    console.log(props.props)

  }

  state = {}

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  logout() {
    console.log("logout")
     auth.signOut()
         .then(() => {
        console.log("logged out")
         })
 }

  render() {
    const { children } = this.props
    const { fixed } = this.state

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            textAlign='center'
            style={styles.hero}
            vertical
          >
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              secondary={!fixed}
              size='large'
            >
              <Container>
                <Menu.Item as={ Link } to='/'>
                  <Image
                    src={require('../../images/newLogo.png')}
                    size='medium'
                  />
                </Menu.Item>
                <Menu.Item>Beta V2</Menu.Item>
                <Menu.Item position='right'>
                  <Button as={ Link } to='/' inverted={!fixed} color='purple' onClick={this.logout}>
                    Logout
                  </Button>
                </Menu.Item>
              </Container>
            </Menu>
            <HomepageHeading props={this.props.props} />
          </Segment>
        </Visibility>

        {children}
      </Responsive>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {

  constructor(props) {
    super(props)
    console.log(props.props.uid)
  }

  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })
  handleToggle = () => this.setState({ sidebarOpened: true })

  logout() {
    console.log("logout")
     auth.signOut()
         .then(() => {
        console.log("logged out")
         })  
  }


  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          as={Menu}
          animation='push'
          inverted
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
        >
          <Menu.Item as={ Link } to="/">Home</Menu.Item>
          <Menu.Item as={ Link } to="/player">Listen</Menu.Item>
          <Menu.Item as='a'>Band Page</Menu.Item>
          <Menu.Item as='a'>Log in</Menu.Item>
          <Menu.Item as='a'>Sign Up</Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            textAlign='center'
            style={styles.heroMobile}
            vertical
          >
            <Container>
              <Menu inverted pointing secondary size='large'>
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name='sidebar' />
                </Menu.Item>
                <Menu.Item as={ Link } to='/' position='right'>
                  <Image
                    style={{ marginLeft: '0.5em' }}
                    src={require('../../images/newLogo.png')}
                    size='small'
                  />
                </Menu.Item>
              </Menu>
            </Container>
            <HomepageHeading props={this.props.props} mobile />
          </Segment>

          {children}
        </Sidebar.Pusher>
      </Responsive>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = (props, { children }) => (
  <div>
    {console.log(props)}
    <DesktopContainer props={props.props}>{children}</DesktopContainer>
    <MobileContainer props={props.props}>{children}</MobileContainer>
  </div>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

const BandPage = (props) => (
  <ResponsiveContainer textAlign='center' props={props}>
    <Segment color='red' vertical style={{ padding: '5em 0em' }}>
    </Segment>  
  </ResponsiveContainer>
)

export default BandPage;


const styles = {
  hero: {
    backgroundImage: 'linear-gradient(to bottom, rgba(20, 18, 37, 255), rgba(29, 12, 69, 255), rgba(38, 21, 89, 255), rgba(48, 24, 100, 255), rgba(75, 21, 109, 255), rgba(130, 9, 118, 255))',
    backgroundSize: 'cover !important',
    height: '100vh',
    width: '100%'
  },
  heroMobile: {
    backgroundImage: 'linear-gradient(to bottom, rgba(20, 18, 37, 255), rgba(29, 12, 69, 255), rgba(38, 21, 89, 255), rgba(48, 24, 100, 255), rgba(75, 21, 109, 255), rgba(130, 9, 118, 255))',
    minHeight: 575,
    padding: '1em 0em'
  },
  swara_purple: {
    color: 'rgba(130, 9, 118, 255)',
  },
  band:{
    height: '100px',
    width: '100px',
  },
  cardSegment: {
    minHeight: 400,
  }
}