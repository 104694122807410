// Initialize Firebase
import * as firebase from 'firebase'
import 'firebase/storage'
import 'firebase/auth'

// Initialize Firebase
var config = {
  apiKey: "AIzaSyBnMtvCUtzGfFY_8_nihrnOP3F9QM0TQ9Y",
  authDomain: "swara01-af4fa.firebaseapp.com",
  databaseURL: "https://swara01-af4fa.firebaseio.com",
  projectId: "swara01-af4fa",
  storageBucket: "swara01-af4fa.appspot.com",
  messagingSenderId: "373464441207",
  appId: "1:604554706072:web:cae8f6255bcb0c658a64ed"
};

firebase.initializeApp(config);
const database = firebase.database()
const storage = firebase.storage();

export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();

export {database}
export {storage, firebase as default}