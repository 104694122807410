import React, { Component } from 'react';
import { 
  Button, 
  Modal, 
  Header, 
  Image, 
  List, 
  Form,
} from 'semantic-ui-react';

class Report extends Component {
  state = { modalOpen: false }

  handleOpen = () => this.setState({ modalOpen: true })
  handleClose = () => this.setState({ modalOpen: false })   
    
  render() {
    return (
      <Modal
        trigger={<List.Item as='a'onClick={this.handleOpen}>Report Song</List.Item>}
        open={this.state.modalOpen}
        onClose={this.handleClose}>
          <Modal.Content image>
            <Image 
              wrapped 
              size='massive'
              src={require('../../images/Copyright.jpg')} 
            />
            <Modal.Description>
              <Header as='h1' style={styles.swara_purple}>Copyright Policy</Header>
                <Header as='h3'>
                  Music uploaded to this site must be of original works by the user.  By uploading the music, 
                  the user is allowing Swara to stream the music to users and declaring that the music is an original work.  
                  Music reported to not be of original work of the user will be promptly removed from the site.  
                </Header>
                <Header as='h3'>
                  Please fill out the form below if you believe this song violates our Copyright Policy. All music reported
                  will be investigated to determine ownership and promptly removed from the site.
                </Header>
          </Modal.Description>
          </Modal.Content>
            <Form>
            <Form.Field>
      <label>First Name</label>
      <input placeholder='First Name' />
    </Form.Field> 
            <Form.Group inline>
          <label>Do you own this music?</label>
          <Form.Radio
            label='Yes'
            onChange={this.handleChange}
            />
          <Form.Radio
            label='No'
            onChange={this.handleChange}
            />
        </Form.Group>
            </Form>
        <Modal.Actions>
          <Button style={styles.swara_purple} onClick={this.handleClose} inverted>Home</Button>
          <Button style={styles.swara_purple} onClick={this.handleClose} inverted>Report</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default Report;

const styles = {
      swara_purple: {
        color: 'rgba(130, 9, 118, 255)',
      }
    }