const genres = [
    { key: 'rock', text: 'Rock', value: 'rock' },
    { key: 'country', text: 'Country', value: 'country' },
    { key: 'blues', text: 'Blues', value: 'blues' },
    { key: 'electric', text: 'Electric', value: 'electric' },
    { key: 'folk', text: 'Folk', value: 'folk' },
    { key: 'hip-hop', text: 'Hip-Hop', value: 'hip-hop' },
    { key: 'jazz', text: 'Jazz', value: 'jazz' },
    { key: 'reggae', text: 'Reggae', value: 'reggae' },
    { key: 'religious', text: 'Religious', value: 'religious' },
    { key: 'classical', text: 'Classical', value: 'classical' },
  ]

  
export default genres;