import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import { Button, Form, Segment, Grid, Header, Icon, Progress } from 'semantic-ui-react';
import {database, storage} from '../backend/config';
import firebase from '../backend/config';

class SongEdit extends Component {
  constructor(props) {
    super(props)
    console.log(props.props)
    console.log(props.props.props.bandId)
    let isEdit = props.props.props.songName!==undefined ? true : false
    let albId = null
    let id = null
    if(isEdit===true) {
      albId = props.props.props.albId
      id = props.props.props.id 
    } else {
      albId = props.props.props.id
      id = null
    }
    this.state = {albId: props.props.props.albId,
                  bndId: props.props.props.bndId,
                  songName: props.props.props.songName,
                  id: props.props.props.id,
                  songFile: null,
                  progress: 0,
                  isEdit: isEdit}

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleUpload = this.handleUpload.bind(this)

  }

  fileInputRef = React.createRef()

  startAddingSong(song) {
    song.rnd = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)

    console.log(song)
    var songKey = song.key
    //if key exist, update existing entry
    if(song.key!==undefined) {
      let songPath = "songs/" + song.key
      delete song.key
      let updateObject ={
          [songPath]: song
      }
      database.ref().update(updateObject).then(() => {
        this.handleUpload(songKey)
        }).catch((error) => {
            console.log(error)
        })
    } 
    //if does not exist, create new entry
    else {  
      delete song.key
      console.log(song)
      const songRef = firebase.database().ref('songs');
      songRef.push(song).then((snap) => {
        this.handleUpload(snap.key)
      })
    }
  }

  handleChange = e => {
    if(e.target.files[0]) {
        const songFile = e.target.files[0];
        this.setState(() => ({songFile}));
    }
}

  handleSubmit(event) {
    console.log("SongEdit handlesubmit")
    console.log(event.target.elements.songName.value)
    event.preventDefault();
    const songName = event.target.elements.songName.value

    console.log(this.state)

    const songProps={
        // id: Object.keys(this.props.song).length>0 ? this.props.song.id : Number (new Date()),
        // albId: Object.keys(this.props.song).length>0 ? this.props.song.albId : this.state.albumId,
        key: this.state.songName !== undefined ? this.state.id : undefined,
        albId: this.state.albId,
        bndId: this.state.bndId,
        songName: songName,
    }
    console.log(songName)
    console.log(this.state.songFile)
    console.log(this.state.isEdit)

    if(songName && (this.state.songFile || (this.state.isEdit))) {
        this.startAddingSong(songProps) 
  //      this.handleUpload(songProps.key);
   }
    
}

handleUpload = (songId) => {
    console.log("handeUpload Entered " + songId)
    if(this.state.isEdit===true) {
      this.setState({progress: 100})
      return
    }

    const {songFile}=this.state
    const uploadTask = storage.ref(`musicFiles/${songId}.mp3`).put(songFile);
    uploadTask.on('state_changed', 
    (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes)*100);
        this.setState({progress})
        if(progress===100)
            // this.props.history.push('/Login')
            console.log("song uploaded")
    }, 
        (error) => {
            console.log(error);
        }
    );
}

render() {
  console.log(this.state)

  if(this.state.progress === 100) {
    return (
      <Redirect to = "/bands"/>
    )
    }

  return(
    <Segment placeholder color='purple'>
      <Grid columns={2} stackable textAlign='center'>
        <Grid.Row>
          <Header icon>
            <Icon name='music' style={{ marginTop: '0.4em' }}  />
              Edit Song
          </Header>
        </Grid.Row>
        <Form onSubmit={this.handleSubmit}>
          <Form.Input focus placeholder='Song Name' name='songName' defaultValue={this.state.songName}/>    
          
          {this.state.songName===undefined? 
          <Button fluid
              basic 
              color='violet'
              content="Choose Song"
              labelPosition="left"
              icon="file"
              type="button"
              onClick={() => this.fileInputRef.current.click()}
            />
            :""}


            <input
              type="file" hidden accept=".mp3" onChange={this.handleChange}
              ref={this.fileInputRef}
              // ref={this.fileInputRef}
              // onChange={this.fileChange}
            />   

          {this.state.progress > 0 ? 
            <Progress size='tiny' color='violet' percent={this.state.progress} /> :
            ""}
          
          {this.state.songFile != null ? this.state.songFile.name : ""}
      
          <br></br>

          <Button.Group>
            <Button color='purple' type='submit'>{this.state.id !== undefined ? "Update" : "Create"}</Button>
          <Button.Or />

          <Link to = {{
                    pathname: '/songs',
                    state: {
                      params: {key: this.state.albId,
                                bndId: this.state.bndId}
                    }
                }}>


            <Button color='purple'>Cancel</Button>
            </Link>
          </Button.Group>   
        </Form>
    
      </Grid>
       <br />
    </Segment>
  ) 
}}

export default SongEdit;