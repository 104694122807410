import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react';

import Fade from 'react-reveal/Fade'; 
import Pulse from 'react-reveal/Pulse'; 

import Map from '../../images/map.png';
import About from '../../images/AboutUs.jpg';
// import About from '../modals/About';
import Copyright from '../modals/Copyright';
import Contact from '../modals/Contact';
// import Down from '../../images/down.svg';


const getWidth = () => {
  const isSSR = typeof window === 'undefined'

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}


const HomepageHeading = ({ mobile }) => (
  <Container text>
    <Header
      as='h1'
      content='live. local. music.'
      inverted
      style={{
        fontSize: mobile ? '2.5em' : '4em',
        fontWeight: 'normal',
        marginBottom: 0,
        marginTop: mobile ? '1.5em' : '2em',
      }}
    />
    <Header
      content='your music wherever you are'
      inverted
      style={{
        fontSize: mobile ? '1.2em' : '1.7em',
        fontWeight: 'normal',
        marginTop: mobile ? '0.5em' : '.10em',
      }}
    />

  <Header textAlign='center' style={styles.down} />

  </Container>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}

class DesktopContainer extends Component {
  
  constructor() {
    super()
    this.state ={}
  }

  hideFixedMenu = () => this.setState({ fixed: false })
  // showFixedMenu = () => this.setState({ fixed: true })

  render() {
    const { children } = this.props
    const { fixed } = this.state

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            textAlign='center'
            style={styles.hero}
            vertical
          >
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              secondary={!fixed}
              size='large'
            >
              <Container>
                <Menu.Item as={ Link } to='/'>
                  <Image
                    src={require('../../images/newLogo.png')}
                    size='medium'
                  />
                </Menu.Item>
                <Menu.Item>Beta V2</Menu.Item>
                <Menu.Item position='right'>
                  <Button as={ Link } to='/signup' inverted={!fixed} color='purple' style={{ marginLeft: '0.5em' }}>
                    Sign Up
                  </Button>
                  {/* <Button as='a' inverted={!fixed} color='purple' style={{ marginLeft: '0.5em' }}>
                    Sign Up
                  </Button> */}
                </Menu.Item>
              </Container>
            </Menu>
            <HomepageHeading />
          </Segment>
        </Visibility>

        {children}
      </Responsive>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          as={Menu}
          animation='push'
          inverted
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
        >
          <Menu.Item as='a' active>
            Home
          </Menu.Item>
          <Menu.Item as={ Link } to="/">Home</Menu.Item>
          <Menu.Item as={ Link } to="/player">Listen</Menu.Item>
          <Menu.Item as={ Link } to="/bands">Band Page</Menu.Item>
          <Menu.Item as='a'>Log in</Menu.Item>
          <Menu.Item as='a'>Sign Up</Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            inverted
            textAlign='center'
            style={styles.heroMobile}
            vertical
          >
            <Container>
              <Menu inverted pointing secondary size='large'>
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name='sidebar' />
                </Menu.Item>
                <Menu.Item position='right'>
                
                  <Button as={ Link } to='/signup' inverted style={{ marginLeft: '0.5em' }}>
                    Sign Up
                  </Button>
                </Menu.Item>
              </Menu>
            </Container>
            <HomepageHeading mobile />
          </Segment>

          {children}
        </Sidebar.Pusher>
      </Responsive>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

const Rock = () => (
  <ResponsiveContainer>
    <Segment style={{ padding: '8em 0em' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              Music You Love in the City You Love
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Swara believes that there is gifted musical talent in every city which deserves an audience.  
              We were inspired by listening to amazing local musicians and want to hear more of their music.  
              We are driven by helping people connect with live local music.
            </p>
            <Header as='h3' style={{ fontSize: '2em' }}>
              Beat the Industry
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Currently, the music industry controls what is popular and successful, but there is unbelievable 
              music happening everywhere. Local music is key to a cities vibe and 
              culture yet it is difficult for musicians to have a local support network.  Well known celebrities 
              dominate and leave nothing for the average local musician.
            <br /><br />
              We want to change that.
            </p>
          </Grid.Column>
          <Grid.Column floated='right' width={8}>
            <Image bordered rounded size='huge' src={require('../../images/stage2.jpg')} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign='center'>
            <Pulse>
            <Button as={ Link } to='/signup' color="purple" size='large'>Join the Movement
              <Icon name='right arrow' />
            </Button>
            </Pulse>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment style={{ padding: '0em' }} vertical>
      <Grid columns='equal' stackable padded>
        <Grid.Row textAlign='center'>
          <Grid.Column  width={8} textAlign='center' verticalAlign='middle'>
            <Fade left>
              <Image src={Map} centered />
            </Fade>
          </Grid.Column>
          <Grid.Column width={8} textAlign='center' verticalAlign='middle'>
            <Fade right>
            <Header as='h3' style={{ fontSize: '2em' }}>
              Powered By Local Search
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Swara's advanced algorithm connects your music to listeners in your area. Whether you're on the 
              road or playing your home venue, live music lovers can discover your music, find your show, and
              see you play live, growing your fan base. Swara believes finding new, local music should be as 
              simple as opening an app.
            </p>
            </Fade>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment style={{ padding: '8em 0em' }} vertical>
      <Container text>
        <Header as='h3' style={{ fontSize: '2em' }}>
        Find Amazing Music
        </Header>
        <p style={{ fontSize: '1.33em' }}>
        Music enthusiast will be able to find music that fit their taste and find when and where that 
        musician will be performing.  Imagine listening to Swara Radio, hearing a song that catches your attention, 
        and then finding out that the musician is playing at a venue nearby.  What a great way to plan a night out.  
        You can also visit the musicians website and purchase their latest album.
        </p>
        {/* <Pulse>
        <Button as='a' size='large'>
          Read More
        </Button>
        </Pulse> */}
        <Divider
          as='h4'
          className='header'
          horizontal
          style={{ margin: '3em 0em', textTransform: 'uppercase' }}
        >
          {/* <a href='#'>Case Studies</a> */}
        </Divider>

        <Header as='h3' style={{ fontSize: '2em' }}>
        Listen to Great Music
        </Header>
        <p style={{ fontSize: '1.33em' }}>
        There are amazing musicians all around us that never gets heard except in local coffee shops and bars.  
        Technology now allows musicians to easily record music using nothing more than their laptop, but getting 
        that music heard is a huge effort and often unsuccessful process.  This project is to develop a website 
        and mobile apps that would create an unique and truly local radio experience.  You can also get a view 
        of ground breaking music is being created in other cities.
        </p>
        {/* <Pulse>
        <Button as='a' size='large'>
          I'm Still Quite Interested
        </Button>
        </Pulse> */}
      </Container>
    </Segment>

    <Segment style={{ padding: '8em 0em' }} vertical>
      <Grid columns='equal' stackable padded>
        <Grid.Row textAlign='center'>
          <Grid.Column textAlign='center' verticalAlign='middle'>
            <Fade right>
            <Header as='h3' style={{ fontSize: '2em' }}>
              Join The Movement
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              We are looking for great bands like yours to launch the
              Swara revolution. Add your music, share your music, get your music heard!
            </p>
            <Button as={ Link } to='/signup' color="purple" size='large'>Join Now
              <Icon name='right arrow' />
            </Button>
            </Fade>
          </Grid.Column>
          
          <Grid.Column textAlign='center' verticalAlign='middle'>
            <Fade left>
            <Image src={About} bordered rounded size='large' centered />
            </Fade>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment basic>

    </Segment>

    <Segment inverted vertical>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <Header inverted as='h4' content='Info' />
              <List link inverted>
                {/* <List.Item><About /></List.Item> */}
                <List.Item><Contact /></List.Item>
                <List.Item><Copyright /></List.Item>
                {/* <List.Item as='a'>Privacy</List.Item> */}
                <List.Item href='https://www.facebook.com'>
                  <Icon name='facebook' />
                   Be Social - Follow Us
                </List.Item>
              </List>
            </Grid.Column>
            {/* <Grid.Column width={3}>
              <Header inverted as='h4' content='Services' />
              <List link inverted>
                <List.Item as='a'>Banana Pre-Order</List.Item>
                <List.Item as='a'>DNA FAQ</List.Item>
                <List.Item as='a'>How To Access</List.Item>
                <List.Item as='a'>Favorite X-Men</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
              <Header as='h4' inverted>
                Footer Header
              </Header>
              <p>
                Extra space for a call to action inside the footer that could help re-engage users.
              </p>
            </Grid.Column> */}
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </ResponsiveContainer>
)

export default Rock;

const styles = {
      hero: {
        backgroundImage: 'linear-gradient(to bottom, rgba(20, 18, 37, 255), rgba(29, 12, 69, 255), rgba(38, 21, 89, 255), rgba(48, 24, 100, 255), rgba(75, 21, 109, 255), rgba(130, 9, 118, 255))',
        minHeight: 700,
        padding: '1em 0em'
      },
      heroMobile: {
        backgroundImage: 'linear-gradient(to bottom, rgba(20, 18, 37, 255), rgba(29, 12, 69, 255), rgba(38, 21, 89, 255), rgba(48, 24, 100, 255), rgba(75, 21, 109, 255), rgba(130, 9, 118, 255))',
        minHeight: 350,
        padding: '1em 0em'
      },
      swara_purple: {
        color: 'rgba(130, 9, 118, 255)',
      },
      bottom: {
        background: 'rgba(130, 9, 118, 255)',
        padding: '5em 0em',
      },
    
      // down: {
      //     height: '50px',
      //     width: '50px',  
      //     backgroundImage: `url(${Down})`,
      //     backgroundRepeat: 'no-repeat',
      //     backgroundSize: 'contain',
      //     animation: 'pulse 3s infinite',
      //     display: 'flex',
      //     justifyContent: 'center',
      //     position: 'absolute',
      //     bottom: '0px',
      //   },
}
