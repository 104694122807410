import React, { Component } from 'react';
import { 
  Button, 
  Modal, 
  Header, 
  Icon, 
  Image, 
  List 
} from 'semantic-ui-react';


class Contact extends Component {
  state = { modalOpen: false }

  handleOpen = () => this.setState({ modalOpen: true })
  handleClose = () => this.setState({ modalOpen: false })
      
  render() {
    return (
      <Modal 
        trigger={<List.Item as='a'onClick={this.handleOpen}>Contact Us</List.Item>}
        open={this.state.modalOpen}
        onClose={this.handleClose}>
          <Modal.Content image>
            <Image 
              wrapped 
              size='medium' 
              src={require('../../images/ContactUs.jpg')} 
            />
          <Modal.Description>
            <Header as='h1' style={styles.swara_purple}>Get In Touch</Header>
              <Header as='h3'>
                  <Header.Content>We are based in Salt Lake City, Utah and look forward to receiving your feedback.</Header.Content>
              </Header>
              <Header as='h3'>
                <Icon name='mail'/>
                  <Header.Content href='mailto:contact@swaralocal.com'>contact@swaralocal.com</Header.Content>
              </Header>
          </Modal.Description>
          </Modal.Content>
            <Modal.Actions>
              <Button style={styles.swara_purple} onClick={this.handleClose}>Home</Button>
            </Modal.Actions>
        </Modal>
    );
  }
}

export default Contact;

const styles = {
  swara_purple: {
    color: 'rgba(130, 9, 118, 255)',
  }
}