import React, { Component } from 'react';
import { DateInput, TimeInput } from 'semantic-ui-calendar-react';
import { Form, Button, Segment, Grid, Header, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import firebase from '../backend/config';
// import { GeoFire } from 'geofire';
// import Geocode from 'react-geocode'

const states = [
      { key: 'Alabama', text: 'Alabama', value: 'AL' },
      { key: 'Alaska', text: 'Alaska', value: 'AK' },
      { key: 'Arizona', text: 'Arizona', value: 'AZ' },
      { key: 'Arkansas', text: 'Arkansas', value: 'AR' },
      { key: 'California', text: 'California', value: 'CA' },
      { key: 'Colorado', text: 'Colorado', value: 'CO' },
      { key: 'Connecticut', text: 'Connecticut', value: 'CT' },
      { key: 'Delaware', text: 'Delaware', value: 'DE' },
      { key: 'District of Columbia', text: 'District of Columbia', value: 'DC' },
      { key: 'Florida', text: 'Florida', value: 'FL' },
      { key: 'Georgia', text: 'Georgia', value: 'GA' },
      { key: 'Hawaii', text: 'Hawaii', value: 'HI' },
      { key: 'Idaho', text: 'Idaho', value: 'ID' },
      { key: 'Illinois', text: 'Illinois', value: 'IL' },
      { key: 'Indiana', text: 'Indiana', value: 'IN' },
      { key: 'Iowa', text: 'Iowa', value: 'IA' },
      { key: 'Kansas', text: 'Kansas', value: 'KS' },
      { key: 'Kentucky', text: 'Kentucky', value: 'KY' },
      { key: 'Louisiana', text: 'Louisiana', value: 'LA' },
      { key: 'Maine', text: 'Maine', value: 'ME' },
      { key: 'Maryland', text: 'Maryland', value: 'MD' },
      { key: 'Massachusetts', text: 'Massachusetts', value: 'MA' },
      { key: 'Michigan', text: 'Michigan', value: 'MI' },
      { key: 'Minnesota', text: 'Minnesota', value: 'MN' },
      { key: 'Mississippi', text: 'Mississippi', value: 'MS' },
      { key: 'Missouri', text: 'Missouri', value: 'MO' },
      { key: 'Montana', text: 'Montana', value: 'MT' },
      { key: 'Nebraska', text: 'Nebraska', value: 'NE' },
      { key: 'Nevada', text: 'Nevada', value: 'NV' },
      { key: 'New Hampshire', text: 'New Hampshire', value: 'NH' },
      { key: 'New Jersey', text: 'New Jersey', value: 'NJ' },
      { key: 'New Mexico', text: 'New Mexico', value: 'NM' },
      { key: 'New York', text: 'New York', value: 'NY' },
      { key: 'North Carolina', text: 'North Carolina', value: 'NC' },
      { key: 'North Dakota', text: 'North Dakota', value: 'ND' },
      { key: 'Ohio', text: 'Ohio', value: 'OH' },
      { key: 'Oklahoma', text: 'Oklahoma', value: 'OK' },
      { key: 'Oregon', text: 'Oregon', value: 'OR' },
      { key: 'Pennsylvania', text: 'Pennsylvania', value: 'PA' },
      { key: 'Rhode Island', text: 'Rhode Island', value: 'RI' },
      { key: 'South Carolina', text: 'South Carolina', value: 'SC' },
      { key: 'South Dakota', text: 'South Dakota', value: 'SD' },
      { key: 'Tennessee', text: 'Tennessee', value: 'TN' },
      { key: 'Texas', text: 'Texas', value: 'TX' },
      { key: 'Utah', text: 'Utah', value: 'UT' },
      { key: 'Vermont', text: 'Vermont', value: 'VT' },
      { key: 'Virginia', text: 'Virginia', value: 'VA' },
      { key: 'Washington', text: 'Washington', value: 'WA' },
      { key: 'West Virginia', text: 'West Virginia', value: 'WV' },
      { key: 'Wisconsin', text: 'Wisconsin', value: 'WI' },
      { key: 'Wyoming', text: 'Wyoming', value: 'WY' },
    ]
  

class GigsNew extends Component {

  constructor() {
    super();
    this.state = {
      date: '',
      time: '',
      venue: '',
      web: '',
      address: '',
      city: '',
      state: '',
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = name => (e, { value }) => this.setState({ [name]: value });

  handleSubmit(e) {
    e.preventDefault();
    const gigsRef = firebase.database().ref('gigs');
    const Gig = {
      date: this.state.date,
      time: this.state.time,
      venue: this.state.venue,
      web: this.state.web,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state

    }
    gigsRef.push(Gig);
    this.setState({
      date: '',
      time: '',
      venue: '',
      web: '',
      address: '',
      city: '',
      state: '',
    });
  }

 
     
render() {
  const {state } = this.state

  return (
    <Segment width={9} color='purple'>
      <Grid stackable textAlign='center'>
        <Grid.Column>
          <Header icon>
            <Icon name='microphone' />
              Add a Show.
          </Header>
    <Form onSubmit={this.handleSubmit}>
      <Form.Group widths='equal'>
        <DateInput 
          dateFormat="MMMM DD, YYYY"
          popupPosition="bottom center"
          name="date"
          placeholder="Date"
          value={this.state.date}
          iconPosition="left"
          onChange={this.handleChange("date")}
        />
        <TimeInput
          timeFormat="ampm"
          popupPosition="bottom center"
          name="time"
          placeholder="Time"
          value={this.state.time}
          iconPosition="left"
          onChange={this.handleChange("time")}
        />
      </Form.Group>
        <Form.Input 
          type="text" 
          placeholder="Venue"
          name="venue"
          defaultValue={this.state.venue}
          onChange={this.handleChange("venue")}
          value = {this.state.venue}
        />
    
        <Form.Input
          type="text" 
          placeholder="Street Address"
          name="address"
          onChange = {this.handleChange("address")}
          value={this.state.address}
        />

      <Form.Group widths='equal'>
        <Form.Input 
          type="text" 
          placeholder="City"
          name="city"
          onChange = {this.handleChange("city")}
          value= {this.state.city}
        />
        <Form.Dropdown 
          placeholder='State' 
          search 
          selection 
          options={states} 
          onChange = {this.handleChange("state")}
          value = {this.state.state}
        />
      </Form.Group>
        <Form.Input 
          placeholder="Venue Website" 
          name="web" 
          max="3"
          defaultValue={this.state.website}
          onChange = {this.handleChange("web")}
          value = {this.state.web}
        />
    <Button.Group>
    <Button color='purple' type='submit'>Create</Button>
  <Button.Or />
    <Button color='purple' type='button' as={Link} to='/bands'>Cancel</Button>
  </Button.Group>
    </Form>

    </Grid.Column>
      </Grid>
    </Segment>  
   );
  }
}

export default GigsNew;

const styles = {
  swara_purple: {
    color: 'rgba(130, 9, 118, 255)',
  }
}