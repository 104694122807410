import React, { Component } from 'react';
import { 
  Button, 
  Modal, 
  Header, 
  Image, 
  List, 
} from 'semantic-ui-react';

class AboutUs extends Component {
  state = { modalOpen: false }

  handleOpen = () => this.setState({ modalOpen: true })
  handleClose = () => this.setState({ modalOpen: false })   
    
  render() {
    return (
      <Modal
        trigger={<List.Item as='a'onClick={this.handleOpen}>About Us</List.Item>}
        open={this.state.modalOpen}
        onClose={this.handleClose}>
          <Modal.Content image>
            <Image 
              wrapped 
              size='massive'
              src={require('../../images/AboutUs.jpg')} 
            />
            <Modal.Description>
              <Header as='h1' style={styles.swara_purple}>About Us</Header>
                <Header as='h3'>
                  Swara believes that there is gifted musical talent in every city which deserves an audience.  
                  We were inspired by listening to amazing local musicians and want to hear more of their music.  
                  We are driven by helping people connect with live local music.
              </Header>
            </Modal.Description>
          </Modal.Content>
        <Modal.Actions>
          <Button style={styles.swara_purple} onClick={this.handleClose} inverted>Home</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default AboutUs;

const styles = {
      swara_purple: {
        color: 'rgba(130, 9, 118, 255)',
      }
    }