import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { 
  Image, 
  Card, 
  Button, 
  Table, 
  Grid, 
  Popup,
} from 'semantic-ui-react';
import {database, storage} from '../backend/config';

import AlbumDelete from '../modals/AlbumDelete';
import ReactHowler from 'react-howler'


class SongViewCard extends Component {
  
  constructor(props) {
    super(props)
    console.log(props)
    this.state = {album: {albnm: this.props.props.albnm,
                  bndId: this.props.props.bndId,
                  albId: this.props.props.key,
                  albumArtUrl: this.props.props.albumArtUrl},
                  songUrl: null,
                  songPlaying: false,
                  songPlayingId: null,
                  selectedBand: null }

 
  }
state = {}

startGettingBandInfo(bandId) {
  var bandPath = "bands/" + bandId 
  var band={}
      database.ref(bandPath).orderByValue().once("value", snapshot=> {
        band = (snapshot.toJSON())
        this.setState({band})
      })  
}


startGettingSongs(albumId){
  if(albumId === undefined)
    return

  console.log("startGettingSongs: " + albumId)
  database.ref('songs').orderByChild('albId').equalTo(albumId).once("value", snapshot => {
      let songs=[]
      snapshot.forEach(function(data) {
          var dataJSON = data.toJSON()
          dataJSON.id = data.key
          songs.push(dataJSON)
          console.log(songs)
      })
      
      this.setState({songs})
  })
}


componentDidMount() {
  console.log(this.state)

  this.startRemovingSong = this.startRemovingSong.bind(this)

  if(this.state.album.albnm===undefined) {
    this.startGettingAlbum(this.state.album.albId)
  }

  this.startGettingBandInfo(this.state.album.bndId)
  this.startGettingSongs(this.state.album.albId)
  if(this.state.albId !== undefined)
    this.startGettingSongs(this.state.album.albId)



}


startGettingAlbum(albumId){
  if(albumId === undefined)
    return
  console.log("starting to get album info " + albumId)
  database.ref(`albums/${albumId}`).once("value", snapshot =>{
      let data = snapshot
      this.setState({album: {...this.state.album,
                  albnm: data.toJSON().albnm}})
      storage.ref(`albumArt/${albumId}.jpg`).getDownloadURL().then(httpUrl => {
        let dataJSON = data.toJSON()
        dataJSON.albumArtUrl= httpUrl
        this.setState({album: {...this.state.album, 
                              albumArtUrl: dataJSON.albumArtUrl}})
      }
      ).catch(error => {
        let dataJSON = data.toJSON()
        dataJSON.albumArtUrl= 'SwaraCoverArt.png'
        this.setState({album: {...this.state.album, 
                              albumArtUrl: dataJSON.albumArtUrl}})
      })  
    
  })      
}


startRemovingAlbum(id) {
  console.log("REMOVING ALBUM: " + id)
  const updatesAlbum = {
      [`albums/${id}`]: null
  }

  database.ref().update(updatesAlbum).then(() => {
    database.ref('songs').orderByChild('albId').equalTo(Number(id)).once("value", snapshot => {
        let songs=[]
        snapshot.forEach(data => {
          songs.push(data.toJSON())
          const updatesSong = {
              [`songs/${data.toJSON().id}`]: null,
          }
          
          database.ref().update(updatesSong).then(() => {
              storage.ref(`musicFiles/${data.toJSON().id}.mp3`).delete()            
              database.ref().update(updatesSong)
          }).catch((error) => {
              console.log(error)
          })
        })
    }).catch((error) => {
        console.log(error)
    })
    storage.ref(`albumArt/${id}.jpg`).delete()
  }).catch((error) => {
    console.log(error)
  })
}


startRemovingSong(song) {
  console.log(song)
  const updates = {
      [`songs/${song.id}`]: null,
     }
  
      database.ref().update(updates).then(() => {
          storage.ref(`musicFiles/${song.id}.mp3`).delete()
          database.ref().update(updates)
          this.startGettingSongs(song.albId)    //TODO:  Could remove from array instead of re-downloading
          console.log("song removed")
      }).catch((error) => {
          console.log(error)
      })
  
}

getSongUrl(song) {
  console.log(song)

  storage.ref(`musicFiles/${song.id}.mp3`).getDownloadURL().then(httpUrl => {
    console.log("url: " + httpUrl)
    this.setState({songUrl: httpUrl,
                    songPlaying: true,
                    songPlayingId: song.id})
    }).catch(function(error) {      
      console.log("Failed to find url for song" + error.message + " Try again.")
    }) //load selected song  
}


render() {
  return (
    <Grid stackable>

      {this.state.songUrl !== undefined && this.state.songUrl !==null ?

        <ReactHowler
        // src={(this.state.url !==undefined) ? this.state.url : this.props.song.url}
        src = {this.state.songUrl}
        //src = 'https://firebasestorage.googleapis.com/v0/b/swara01-af4fa.appspot.com/o/musicFiles%2F1544908211242.mp3?alt=media&token=68a883a3-076e-4348-8e6f-7b0d7bb14133'
        playing={this.state.songPlaying}
        onLoad={this.handleOnLoad}
        onLoadError={this.handleOnLoadError}
        onPlay={this.handleOnPlay}
        onEnd={this.handleOnEnd}
        loop={this.state.loop}
        mute={this.state.mute}
        volume={this.state.volume}
        ref={(ref) => (this.player = ref)}
        />
      : ""}

      <Grid.Row></Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>   
          <Card centered>
            <Image 
              style={styles.albumArt}
              src={(this.state.album.albumArtUrl)}
            />
            <Card.Content>
              {console.log(this.state)}
              <Card.Header textAlign='left'>{this.state.band!==undefined ? this.state.band.name : ""}</Card.Header>
              <Card.Description textAlign='left'>{this.state.album.albnm}</Card.Description>
              <Card.Description textAlign='left'>{this.state.band !==undefined ? this.state.band.city: ""}, {this.state.band!==undefined ? this.state.band.state : ""}</Card.Description>
                <br />
              <AlbumDelete props={this.state.album.albId}/>
               
                <Link to = {{
                    pathname: '/new_album',
                    state: {
                      params: {albums: this.state.album}
                    }
                }}>
                  <Popup
                    trigger={<Button circular icon='edit' floated='left' color='violet' />}
                    content='Edit Album'
                    hideOnScroll
                    style={styles.pop}
                    inverted
                  />
              </Link>
              {/* <Link to="/edit_song"> */}

              <Link to = {{
                    pathname: '/edit_song',
                    state: {
                      params: {singleName: this.state.album}
                    }
                }}>


                <Popup
                  trigger={<Button circular icon='add' floated='left' color='violet'></Button>}
                  content='Add Song to Album'
                  hideOnScroll
                  style={styles.pop}
                  inverted
                />
              </Link>


              <Link to = {{
              pathname: '/bands',
              state: {
                selectedBand: this.state.album.bndId
              }
            }} {...console.log(this.state.album.bndId)}>
<Popup
                  trigger={<Button circular icon='arrow left' floated='left' color='violet'/>}
                  content='Back to Albums'
                  hideOnScroll
                  style={styles.pop}
                  inverted
                />
              </Link>
     


            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column style={styles.tableScroll}>
          <Table celled inverted selectable style={styles.table}>
            <Table.Body>

{this.state.songs!==undefined ? this.state.songs.map((singleName, index) =>
            <Table.Row key={index}>
                <Table.Cell>{index+1}.     {singleName.songName}</Table.Cell>
                <Table.Cell verticalAlign='middle'>
                  <Popup
                    trigger={<Button circular icon='delete' floated='right' color='violet' onClick = {()=> this.startRemovingSong(singleName)} />}
                    content='Delete Song'
                    hideOnScroll
                    style={styles.pop}
                    inverted
                  />

                  <Link to = {{
                    pathname: '/edit_song',
                    state: {
                      params: {singleName}
                    }
                  }}>
                  <Popup
                    trigger={<Button circular icon='edit' floated='right' color='violet'{...console.log(singleName)}/> }
                    content='Edit Song'
                    hideOnScroll
                    style={styles.pop}
                    inverted
                  />
                </Link>
                  {(this.state.songPlaying === true && this.state.songPlayingId ===singleName.id) ?
                  <Popup
                  trigger={<Button circular icon='stop' onClick = {() =>{this.setState({songPlaying: false})}} floated='right' color='violet' />}
                  content='Stop'
                  hideOnScroll
                  style={styles.pop}
                  inverted
                  />:
                  <Popup
                  trigger={<Button circular icon='play' onClick = {() => this.getSongUrl(singleName)} floated='right' color='violet' />}
                  content='Play Song'
                  hideOnScroll
                  style={styles.pop}
                  inverted
                />}
                </Table.Cell>
              </Table.Row>
              ):<Table.Row></Table.Row>}
            </Table.Body>   
          </Table>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row></Grid.Row>
    </Grid>
  )
}}

export default SongViewCard;

const styles = {
  card: {
    width: '300px',
  },
  albumArt: {
    width: '300px',
  },
  segment: {
    width: '300px',
    backgroundImage: 'linear-gradient(to bottom, rgba(20, 18, 37, 50), rgba(14, 15, 40, 50))',
  },
  text: {
    color: 'white',
 },
  pop: {
    borderRadius: 5,
    opacity: .96,
    padding: '1.5em',
 },
 table: {
    backgroundImage: 'linear-gradient(to bottom, rgba(20, 18, 37, 50), rgba(14, 15, 40, 50))',
    color: 'white',
 },
 tableScroll: {
  maxHeight: '420px',
  overflowY: 'auto',
 },
}



