import React, { Component } from 'react';
import './App.css';
import { Route, Switch, Redirect } from 'react-router-dom';
import Home from './components/ui/Home';
import Player from './components/ui/PlayerPage';
import Bands from './components/ui/BandPage';
import LoginUi from './components/ui/LoginUi';
import SignUpUi from './components/ui/SignUpUi';
import BandCopyright from './components/ui/BandCopyright';
import NewAlbum from './components/ui/NewAlbum';
import EditAlbum from './components/ui/EditAlbum';
import SongView from './components/ui/SongView';
import NewSong from './components/ui/NewSong';
import EditSong from './components/ui/EditSong';
import NewBand from './components/ui/NewBand';
import Rock from './components/ui/Rock';
import Gigs from './components/ui/NewGig';

import { AuthProvider } from './components/auth/Auth';
import firebase from './components/backend/config';
import PrivateRoute from './PrivateRoutes';

import { 
  Loader, 
  Dimmer 
} from 'semantic-ui-react';


class App extends Component {
  state = { loading: true, authenticated: false, user: null };

  componentWillMount() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({
          authenticated: true,
          currentUser: user,
          loading: false,
          loggedIn: true
        });
      } else {
        this.setState({
          authenticated: false,
          currentUser: null,
          loading: false,
          loggedIn: false
        });
      }
    });
  }

  render(){

    const { loading } = this.state;

    if (loading) {
      return <Dimmer active><Loader size='large' /></Dimmer>
    }
      return (
        <AuthProvider>
          <Switch>

            <PrivateRoute exact path='/bands' component={Bands} authenticated={this.state.authenticated} currentUser={this.state.currentUser} />
            <PrivateRoute exact path='/bandcopyright' component={BandCopyright} authenticated={this.state.authenticated} currentUser={this.state.currentUser} />
            <PrivateRoute exact path='/new_album' component={NewAlbum} authenticated={this.state.authenticated} currentUser={this.state.currentUser} />
            <PrivateRoute exact path='/edit_album' component={EditAlbum} authenticated={this.state.authenticated} currentUser={this.state.currentUser} />
            <PrivateRoute exact path='/songs' component={SongView} authenticated={this.state.authenticated} currentUser={this.state.currentUser} />
            <PrivateRoute exact path='/new_song' component={NewSong} authenticated={this.state.authenticated} currentUser={this.state.currentUser} />
            <PrivateRoute exact path='/edit_song' component={EditSong} authenticated={this.state.authenticated} currentUser={this.state.currentUser} />
            <PrivateRoute exact path='/new_band' component={NewBand} authenticated={this.state.authenticated} currentUser={this.state.currentUser} />
            <PrivateRoute exact path='/gigs' component={Gigs} authenticated={this.state.authenticated} currentUser={this.state.currentUser} />


            <Route exact path='/' component={Home} />
            <Route path='/player' component={Player} />
            <Route exact path='/login' component={LoginUi} />
            <Route exact path='/signup' component={SignUpUi} />
            <Route exact path='/rock' component={Rock} />

          </Switch>
        </AuthProvider>
      );
  }
}

export default App;
