/*
Contains the player.  The song is loaded through the prop.song.  
When calling actions that require the number of songs, will provide props.dbSize to action.js
*/

import React, {Component} from 'react'
import ReactHowler from 'react-howler'
import raf from 'raf' // requestAnimationFrame polyfill
import { Button } from 'semantic-ui-react'
import Geocode from 'react-geocode'
import {database, storage} from '../backend/config'
import { GeoFire } from 'geofire';


class PlayerPlayer extends Component {
  constructor (props) {
    super(props)

    this.state = {
      playing: false,
      loaded: false,
      loop: false,
      mute: false,
      volume: 1.0,
      continue: false,
      song: {},
      band: {},
      initPlay: false,
      findingSong: false ,
      random: this.props.random,
      autoUnlock: true,
      html5: true
    }

    this.handleToggle = this.handleToggle.bind(this)
    this.handleOnLoad = this.handleOnLoad.bind(this)
    this.handleOnLoadError = this.handleOnLoadError.bind(this)
    this.handleOnEnd = this.handleOnEnd.bind(this)
    this.handleOnPlay = this.handleOnPlay.bind(this)
    this.handleStop = this.handleStop.bind(this)
    this.renderSeekPos = this.renderSeekPos.bind(this)
    this.handleLoopToggle = this.handleLoopToggle.bind(this)
    this.handleMuteToggle = this.handleMuteToggle.bind(this)
    this.handleForward = this.handleForward.bind(this)   
    this.startGettingBands = this.startGettingBands.bind(this)
    this.startFindingSong = this.startFindingSong.bind(this)
    this.onplayerror = this.onplayerror.bind(this)
    this.getHowler = this.getHowler.bind(this)

    
    Geocode.setApiKey('AIzaSyBnMtvCUtzGfFY_8_nihrnOP3F9QM0TQ9Y') 
  }

  getHowler() {
    this.player.howler()
  }

  componentDidMount() {
    this.setState({random : this.props.random})
    ReactHowler.autoUnlock = true
    ReactHowler.html5 = true
  }

  startGettingRandomSong() {
    console.log("startGettingRandomSong")
    var randomNumber = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)
    console.log(randomNumber)
    database.ref('songs').orderByChild('rnd').startAt(randomNumber).limitToFirst(1).once("value", response => {
      if(!response.exists()) {
        //if nothing found, go the other direction
        console.log("reverse search")
        database.ref('songs').orderByChild('rnd').endAt(randomNumber).limitToLast(1).once("value", response2 => {
          console.log(response2.toJSON().key)
          response2.forEach(data2 => {
            console.log(data2.toJSON())
            console.log(data2.key)

            var bandPath = "bands/" + data2.toJSON().bndId  
            database.ref(bandPath).orderByValue().once("value", snapshot2 => {
  
              storage.ref(`albumArt/${data2.toJSON().albId}.jpg`).getDownloadURL().then(httpUrl => {
                this.setState({albumArtUrl: httpUrl})
                this.setState({band: snapshot2.toJSON()})
              }
              ).catch(error => {
                this.setState({albumArtUrl: 'SwaraCoverArt.png'})
                this.setState({band: snapshot2.toJSON()})
              }) 
  
            }) //return band information
            this.setState({songInfo: data2.toJSON()})
            this.startPlayingSingleSong(data2.key)
          })
          
        })
      }
      else {
        console.log(response.toJSON())
        console.log(response.key)
        
        response.forEach(data => {
          console.log(data.toJSON())
          console.log(data.key)

          var bandPath = "bands/" + data.toJSON().bndId  
          database.ref(bandPath).orderByValue().once("value", snapshot => {

            storage.ref(`albumArt/${data.toJSON().albId}.jpg`).getDownloadURL().then(httpUrl => {
              this.setState({albumArtUrl: httpUrl})
              this.setState({band: snapshot.toJSON()})
            }
            ).catch(error => {
              this.setState({albumArtUrl: 'SwaraCoverArt.png'})
              this.setState({band: snapshot.toJSON()})
            }) 

          }) //return band information

          this.setState({songInfo: data.toJSON()})
          this.startPlayingSingleSong(data.key)
        //songList.push(data.toJSON())
        })
      }
    })
  }


  startNarrowingBands(bandList) {
    console.log("startNarrowingBands")
    var randomBand = this.pickRandom(bandList)
    var genrePath = "bands/" + randomBand + "/genre"
    database.ref(genrePath).orderByValue().once("value", response => {
      let genres = []
      response.forEach(function(data) {
        genres.push(data.toJSON())
      })
      if(this.props.genre.length===0) {
        console.log("no genre selected")
        this.setState({song: -1})
      }

      this.props.genre.forEach(element => {
        var bandFound = false
        if((genres.find((str) => str===element) !==undefined) && bandFound ===false) {
          console.log("found band" + randomBand)
          this.startGettingSong(bandList, randomBand)
          bandFound = true
        }
        else {
          console.log("no genre match")
          console.log(element)
        }

        if(bandFound===false) {
          this.setState({song: -1})
        }
      })
    })
  }


  pickRandom(data) {
    if(data.length>0) {
      var random =Math.floor(Math.random() * (data.length))
      return data[random]
    }
    else if (Object.keys(data).length >0 ) {
      random =Math.floor(Math.random() * (Object.keys(data).length))
      return data[random]
    }

  }

  startGettingSong(bandList, band) {
    console.log("startGettingSong " + band)
    var songList =[]
    database.ref('songs').orderByChild('bndId').equalTo(band).once("value", response => {
      response.forEach(function(data) {
        var dataJSON = data.toJSON()
        dataJSON.key = data.key
        songList.push(dataJSON)
      })
      
      if(songList.length>0)
        console.log("song Found")
    

      var randomSong = this.pickRandom(songList)
     
      if(songList.length>0) {
        storage.ref(`musicFiles/${randomSong.key}.mp3`).getDownloadURL().then(httpUrl => {
          randomSong.url=httpUrl
          console.log(randomSong)
          
        
          
          var bandPath = "bands/" + randomSong.bndId  
          database.ref(bandPath).orderByValue().once("value", snapshot => {

              storage.ref(`albumArt/${randomSong.albId}.jpg`).getDownloadURL().then(httpUrl => {
                this.setState({albumArtUrl: httpUrl})
                this.setState({song: randomSong,
                  bands: bandList,
                  band: snapshot.toJSON()})
              }
              ).catch(error => {
                this.setState({albumArtUrl: 'SwaraCoverArt.png'})
                this.setState({song: randomSong,
                  bands: bandList,
                  band: snapshot.toJSON()})
              }) 

            }) //return band information
          
          }).catch(function(error) {
            
                console.log("Failed to find url for song" + error.message + " Try again.")
              
            
          }) //load selected song  
      }
      else {
        console.log("selected band have no songs")
      //  this.setState({findingSong: false})
        this.startFindingSong()
      }
      

      })
  }

  startPlayingSingleSong(id) {
    var randomSong = {
        url: null,
        id: null
    }
    storage.ref(`musicFiles/${id}.mp3`).getDownloadURL().then(httpUrl => {
      
      console.log("url: " + httpUrl)
      randomSong.url=httpUrl
      
      console.log(randomSong)
      this.setState({song: randomSong})
    
      
      // var bandPath = "bands/" + randomSong.bndId  
      // database.ref(bandPath).orderByValue().once("value", snapshot => {
      //      console.log(snapshot.toJSON())
      //       this.setState({band: snapshot.toJSON()})
      //   }) //return band information
      
      }).catch(function(error) {
        
            console.log("Failed to find url for song" + error.message + " Try again.")
          
        
      }) //load selected song  
  }
  
  startFindingSong() {
    console.log("startFindingSong")
    if(this.state.findingSong ===false && this.state.random ===true) {
      this.startGettingRandomSong()
    }
    else if(this.state.findingSong ===false) {
    //  this.setState({initPlay: false})
      this.startGettingBands()
    }

  }

  startGettingNextSong() {
    console.log("startGettingNextSong")
    this.startNarrowingBands(this.state.bands)
  }


  startGettingBands() {
    console.log("startGettingBands")
    console.log(this.props.city)
    console.log(this.props.state)
    console.log(this.props.distance)
    Geocode.fromAddress(this.props.city + ", " + this.props.state).then(
      response => {
        const { lat, lng } = response.results[0].geometry.location;
        var firebaseRef = database.ref('location')
        var geoFire = new GeoFire(firebaseRef)

        var geoQuery = geoFire.query({
            center: [lat, lng],
            radius: Number(this.props.distance)
          });

        var bandList=[]
        var onKeyEnteredRegistration = geoQuery.on("key_entered", function(key, location, distance) {
          console.log("Band" + key + " found at " + location + " (" + distance + " km away)")
          bandList.push(key)
          console.log(bandList.length)
        })

        geoQuery.on("ready", response => {
          onKeyEnteredRegistration.cancel()
      //    this.setState({bands : bandList})
          
          console.log("bandList")
          console.log(bandList.length)
          if(bandList.length === 0) {  //nothing found
            console.log("no bands found")
            const songNotFound = -1
            this.setState({song : songNotFound})
            this.props.action(this.state)
          }
          else
            this.startNarrowingBands(bandList)
        })

        
      }
    )
  }


  componentWillUnmount () {
    this.clearRAF()
  }

  handleToggle () {
      //toggle play and pause if song is loaded
      console.log("handleToggle in Fullcontrol")
      if(this.state.loaded===true) {
        this.setState({
          playing: !this.state.playing
        })
      }
     // this.props.action(this.state)
  }

  handleOnLoad () {
    console.log("FullControl handleOnLoad")
    this.setState({
      loaded: true,
      duration: this.player.duration(),
      playing: true   //needed to start playing on load and play button click
    })
    this.props.action(this.state)
  }

  handleOnLoadError () {
    console.log("FullControl handleOnLoadError " + this.state.song.url)
    if(this.state.song.url !== undefined) {
      this.setState({
        loaded: false,
        playing: false,   //needed to start playing on load and play button click
    //    initPlay: false
      })
   // var clearSong=this.props
   // clearSong.url=undefined
   // this.props.updatePlayingSong(clearSong)
    this.startFindingSong()
    }
  }

  handleOnPlay () {
    if(this.props.songName !=="")
    {
      this.setState({
        playing: true,
      })
      this.renderSeekPos()
      }
  }

  handleOnEnd () {
    //clear url to prevent a breif playing of previous song before new song loads
    // var clearSong=this.props.playingSong
    // clearSong.url=undefined
    // this.props.updatePlayingSong(clearSong)
    this.clearRAF()
    this.handleForward()
    
  }

  handleStop () {
    //check to see if playing since stoping a player that is not loaded will cause an error
    if(this.state.playing===true) {
      this.player.stop()
      this.setState({
        playing: false // Need to update our local state so we don't immediately invoke autoplay
      })
      this.setState({continue: false})
      this.renderSeekPos()
    }
  }

  handleLoopToggle () {
    this.setState({
      loop: !this.state.loop
    })
  }

  handleMuteToggle () {
    this.setState({
      mute: !this.state.mute
    })
  }


  handleForward() {
    if(this.state.random===true)  {
      this.startGettingRandomSong()
    }
    else {
      var error = this.startGettingNextSong()
      console.log(error)
    }

    if(this.setState.playing===true) {
      this.setState({playing: false})
      this.setState({continue: true})
    }
  }


  renderSeekPos () {
    // this.setState({
    //   seek: this.player.seek()
    // })

    if (this.state.playing) {
      this._raf = raf(this.renderSeekPos)
    }
  }

  clearRAF () {
    raf.cancel(this._raf)
  }

  onplayerror () {
    console.log("onplayerror")
    this.player.once('unlock', 
      this.player.play())
  }


  componentDidUpdate(prevProps, prevState) {
    Object.entries(this.props).forEach(([key, val]) =>
      prevProps[key] !== val && console.log(`Prop '${key}' changed`)
    );
    Object.entries(this.state).forEach(([key, val]) =>
      prevState[key] !== val && console.log(`State '${key}' changed`)
    );
  }


  render () {
    console.log("render")
    console.log(this.props)
    console.log(this.state)
    if(this.props.startPlaying===true && this.state.playing===false && this.state.initPlay===false) {
      console.log("startfindingsong in render")
      this.startFindingSong()
      this.setState({initPlay: true})
    }

    if(this.props.playingSong=== -2) {
      console.log("full control received -2 so trying again")
      this.handleForward()
    }

    console.log(this.state)
    if(this.state.song.url !== undefined && this.state.song.url !==null)
    { 
    return (
      <div className='full-control'>
        <ReactHowler
        // src={(this.state.url !==undefined) ? this.state.url : this.props.song.url}
        src = {this.state.song.url}
       //src = 'https://firebasestorage.googleapis.com/v0/b/swara01-af4fa.appspot.com/o/musicFiles%2F1544908211242.mp3?alt=media&token=68a883a3-076e-4348-8e6f-7b0d7bb14133'
        playing={(this.state.continue === true) ? true : this.state.playing}
        onLoad={this.handleOnLoad}
        onLoadError={this.handleOnLoadError}
        onPlay={this.handleOnPlay}
        onEnd={this.handleOnEnd}
        loop={this.state.loop}
        mute={this.state.mute}
        volume={this.state.volume}
        autoUnlock={this.state.autoUnlock}
        html5={this.state.html5}
        ref={(ref) => (this.player = ref)}
        />

        {(this.state.loaded)}

        
        


    <Button icon='stop' size='tiny' color='violet' onClick={this.handleStop} style={{ marginTop: '1em' }} />
    <Button basic circular size='big' color='violet' onClick={this.handleToggle} icon={this.state.playing===true? 'pause' : 'play'} style={{ marginTop: '1em' }}/>
    <Button icon='forward' size='tiny' color='violet' onClick={this.handleForward} style={{ marginTop: '1em' }} />


      </div>
    )
    }
    else
      return(
     //   <div>Waiting to Rock</div>
     
     <div>
    <Button color='violet' size='tiny' icon='stop' onClick={this.handleStop} style={{ marginTop: '1em' }} />
    <Button circular basic color='violet' size='big' onClick={this.handleToggle} icon={this.state.playing===true? 'pause' : 'play'} style={{ marginTop: '1em' }}/>
    <Button color='violet' size='tiny' icon='forward' onClick={this.handleForward} style={{ marginTop: '1em' }}/>
 
  </div>
      )
  }
}

export default PlayerPlayer;
