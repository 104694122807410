//Edit or add a band.  If band store is empty it is new band. If not then it is an update to existing band.

import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import { Grid, Form, Segment, Header, Icon, Button } from 'semantic-ui-react';
import {database} from '../backend/config';
import { GeoFire } from 'geofire';
import Geocode from 'react-geocode'
import firebase from '../backend/config'

const genres = [
    { key: 'rock', text: 'Rock', value: 'rock' },
    { key: 'country', text: 'Country', value: 'country' },
    { key: 'blues', text: 'Blues', value: 'blues' },
    { key: 'electric', text: 'Electric', value: 'electric' },
    { key: 'folk', text: 'Folk', value: 'folk' },
    { key: 'hip-hop', text: 'Hip-Hop', value: 'hip-hop' },
    { key: 'jazz', text: 'Jazz', value: 'jazz' },
    { key: 'reggae', text: 'Reggae', value: 'reggae' },
    { key: 'religious', text: 'Religious', value: 'religious' },
    { key: 'classical', text: 'Classical', value: 'classical' },
  ]

const states = [
    { key: 'Alabama', text: 'Alabama', value: 'AL' },
    { key: 'Alaska', text: 'Alaska', value: 'AK' },
    { key: 'Arizona', text: 'Arizona', value: 'AZ' },
    { key: 'Arkansas', text: 'Arkansas', value: 'AR' },
    { key: 'California', text: 'California', value: 'CA' },
    { key: 'Colorado', text: 'Colorado', value: 'CO' },
    { key: 'Connecticut', text: 'Connecticut', value: 'CT' },
    { key: 'Delaware', text: 'Delaware', value: 'DE' },
    { key: 'District of Columbia', text: 'District of Columbia', value: 'DC' },
    { key: 'Florida', text: 'Florida', value: 'FL' },
    { key: 'Georgia', text: 'Georgia', value: 'GA' },
    { key: 'Hawaii', text: 'Hawaii', value: 'HI' },
    { key: 'Idaho', text: 'Idaho', value: 'ID' },
    { key: 'Illinois', text: 'Illinois', value: 'IL' },
    { key: 'Indiana', text: 'Indiana', value: 'IN' },
    { key: 'Iowa', text: 'Iowa', value: 'IA' },
    { key: 'Kansas', text: 'Kansas', value: 'KS' },
    { key: 'Kentucky', text: 'Kentucky', value: 'KY' },
    { key: 'Louisiana', text: 'Louisiana', value: 'LA' },
    { key: 'Maine', text: 'Maine', value: 'ME' },
    { key: 'Maryland', text: 'Maryland', value: 'MD' },
    { key: 'Massachusetts', text: 'Massachusetts', value: 'MA' },
    { key: 'Michigan', text: 'Michigan', value: 'MI' },
    { key: 'Minnesota', text: 'Minnesota', value: 'MN' },
    { key: 'Mississippi', text: 'Mississippi', value: 'MS' },
    { key: 'Missouri', text: 'Missouri', value: 'MO' },
    { key: 'Montana', text: 'Montana', value: 'MT' },
    { key: 'Nebraska', text: 'Nebraska', value: 'NE' },
    { key: 'Nevada', text: 'Nevada', value: 'NV' },
    { key: 'New Hampshire', text: 'New Hampshire', value: 'NH' },
    { key: 'New Jersey', text: 'New Jersey', value: 'NJ' },
    { key: 'New Mexico', text: 'New Mexico', value: 'NM' },
    { key: 'New York', text: 'New York', value: 'NY' },
    { key: 'North Carolina', text: 'North Carolina', value: 'NC' },
    { key: 'North Dakota', text: 'North Dakota', value: 'ND' },
    { key: 'Ohio', text: 'Ohio', value: 'OH' },
    { key: 'Oklahoma', text: 'Oklahoma', value: 'OK' },
    { key: 'Oregon', text: 'Oregon', value: 'OR' },
    { key: 'Pennsylvania', text: 'Pennsylvania', value: 'PA' },
    { key: 'Rhode Island', text: 'Rhode Island', value: 'RI' },
    { key: 'South Carolina', text: 'South Carolina', value: 'SC' },
    { key: 'South Dakota', text: 'South Dakota', value: 'SD' },
    { key: 'Tennessee', text: 'Tennessee', value: 'TN' },
    { key: 'Texas', text: 'Texas', value: 'TX' },
    { key: 'Utah', text: 'Utah', value: 'UT' },
    { key: 'Vermont', text: 'Vermont', value: 'VT' },
    { key: 'Virginia', text: 'Virginia', value: 'VA' },
    { key: 'Washington', text: 'Washington', value: 'WA' },
    { key: 'West Virginia', text: 'West Virginia', value: 'WV' },
    { key: 'Wisconsin', text: 'Wisconsin', value: 'WI' },
    { key: 'Wyoming', text: 'Wyoming', value: 'WY' },
  ]


class BandNew extends Component {

  constructor(props) {
    super(props)
    console.log(props.props.props.bandId)
    this.state={
      genre: [],
      state: "",
      submitted: false,
      userId: props.props.props.userId,
      bandId: props.props.props.bandId,
      bandName: "",
      website: "",
      bio: "",
      city: "",
      band: {key: null}
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)


    if(props.props.props.bandId != null) {
      console.log(props.props.props.bandId)
    //  this.startGettingBandInfo(props.props.props.bandId)
    }

  }

  componentDidMount() {
    this.startGettingBandInfo = this.startGettingBandInfo(this)
  }

  handleSubmit(event) {
    console.log("SUBMIT")
    console.log(this.state)
    console.log(event.target.elements)
    var bandInfo={}
    bandInfo.name = event.target.elements.bandName.value
    bandInfo.bio = event.target.elements.bio.value
    bandInfo.city = event.target.elements.city.value
    bandInfo.state = this.state.state
    bandInfo.web = event.target.elements.web.value
    bandInfo.genre = this.state.genre
    bandInfo.userID = this.state.userId
    bandInfo.key = this.state.key

    if(this.state.band.key!=null) {
      bandInfo.key = this.state.bandId
    }

    this.startAddingBand(bandInfo)

    console.log(bandInfo)
  }


  handleChange = name => (e, { value }) => {
    //if genre, limit to 3 selections
    if(name === "genre" && value.length <= 3)
      this.setState({ [name]: value })
    //if not just assign
    else if (name !=="genre")
      this.setState({ [name]: value })
  }

  startGettingBandInfo(bandIdInput) {
    var bandId = bandIdInput.state.bandId
    if(bandId === undefined || bandId === null ||bandId ==="none")
      return
    console.log(bandId)
    database.ref(`bands/${bandId}`).once("value", snapshot =>{
      let genres=[]
      console.log(snapshot.toJSON())
      this.setState({bandName: snapshot.toJSON().name,
                      bio: snapshot.toJSON().bio,
                      city: snapshot.toJSON().city,
                      state: snapshot.toJSON().state,
                      website: snapshot.toJSON().web,
                      genre: snapshot.toJSON().genre,
                      key: bandId
      })
    }).catch(error => {
      console.log(error)
    })   
  }

  startAddingBand(band) {
    
    // database.ref('bands').update({[band.id]: band}).then(() => {
    //   }).catch((error) => {
    //       console.log(error)
    
    band.rnd = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)

    console.log(band)

    if(band.key !== undefined) {
      console.log(band)
      let bandPath = "bands/" + band.key
      delete band.key
      let updateObject = {[bandPath]: band}
      database.ref().update(updateObject).then(() => {
        this.setState({submitted: true})
      }). catch((error) => {
        console.log(error)
      })
    }
    else {
      delete band.key
      console.log(band)
      const bandRef = firebase.database().ref('bands');
      bandRef.push(band).then((snap) => {
        band.key = snap.key
        this.startAddingBandLocation(band)
      })
    }

  }


startAddingBandLocation(band) {
    console.log("adding band location")
    console.log(band)
    console.log(band.key)
    console.log(band.city)
    console.log(band.state)

//TODO: remove hardcode key.  Reference config.js
    Geocode.setApiKey('AIzaSyBnMtvCUtzGfFY_8_nihrnOP3F9QM0TQ9Y')
    Geocode.enableDebug()

    //change city and state to latitude and longitude
    Geocode.fromAddress(band.city + ", " + band.state).then(
        response => {
            const { lat, lng } = response.results[0].geometry.location;
            console.log(lat, lng);

            var firebaseRef = database.ref('location')
            var geoFire = new GeoFire(firebaseRef)

            let location = {
                [`${band.key}`]: [lat, lng],
            }

            geoFire.set(location).then(() => {
                console.log("Provided keys have been added to GeoFire")
                this.setState({submitted: true})
            }, function(error) {
                console.log(error)
            })
        },
        error => {
          console.error(error)
        }
      )
}

render() {
  const {state, genre } = this.state

  console.log(this.state.bio)

  if(this.state.submitted ===true)
    return (
      <Redirect to = "/bands"/>
    )

  return(
    <Segment width={9} color='purple'>
      <Grid stackable textAlign='center'>
        <Grid.Column>
          <Header icon>
            <Icon name='microphone' />
              Tell us about your band.
          </Header>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group widths='equal'>
              <Form.Input 
                type="text" 
                placeholder="Band Name"
                name="bandName"
                defaultValue={this.state.bandName}
              />
              <Form.Input 
                placeholder="Website" 
                name="web" 
                max="3"
                defaultValue={this.state.website}
              />
              <Button type="button" href={this.state.website} target="_blank">Test</Button>
              {/* <Popup
                trigger={<a href={this.props.band.web} target="_blank"><i className="check circle icon" color='white'></i></a>}
                content='Test Web Link'
                hideOnScroll
                style={styles.pop}
                inverted
                position='top right'
              />  */}
              </Form.Group>
              <Form.Group widths='equal'>
              <Form.TextArea 
                type="text" 
                placeholder="Bio - Tell the world about your band!"
                name="bio"
                onChange = {this.handleChange("bio")}
                value={this.state.bio}
              />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Input 
                type="text" 
                placeholder="City"
                name="city"
                onChange = {this.handleChange("city")}
                value= {this.state.city}
              />
              <Form.Dropdown 
                placeholder='State' 
                search 
                selection 
                options={states} 
                onChange = {this.handleChange("state")}
                value = {this.state.state}
              />
            </Form.Group>
            <Form.Dropdown
              placeholder='Select Your Genres'
              fluid
              multiple
              search
              selection
              options={genres}
              onChange = {this.handleChange("genre") }
              value = {this.state.genre}
            />
            <Button.Group>
              <Button color='purple' type='submit'>{this.state.bandId!=null? "Update": "Create"}</Button>
            <Button.Or />
              {this.state.bandId !=="none" ? <Button color='purple' type='button' as={Link} to='/bands'>Cancel</Button>
                :<Button color='purple' type='button' as={Link} to='/'>Cancel</Button>}
            </Button.Group>
          </Form>
        </Grid.Column>
      </Grid>
    </Segment>  
  )
}}

export default BandNew;
